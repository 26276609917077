/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { ENDPOINTS } from "apis";
import { IAuthToken, IUser } from "apis/schema/core";
import { ISubscriptionStatus } from "apis/schema/stripe";
import api from "shared/utils/api";
import { theme } from "themes";

export const googleSignInButtonId = "googleSignInButton";
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

interface AuthContextType {
  authToken: string;
  refreshToken: string;
  setAuthToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  login: (data: FormData) => Promise<any>;
  logout: () => void;
  handleGoogleCallbackResponse: (response: google.accounts.id.CredentialResponse) => void;
  isSubscribed: boolean | null;
}

export const AuthContext = createContext<AuthContextType>({
  authToken: localStorage.getItem("authToken") || "",
  refreshToken: localStorage.getItem("refreshToken") || "",
  setAuthToken: (token: string) => {},
  setRefreshToken: (token: string) => {},
  login: (data: FormData) => new Promise(() => {}),
  logout: () => {},
  handleGoogleCallbackResponse: (response: google.accounts.id.CredentialResponse) => {},
  isSubscribed: null,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authToken, setAuthToken] = useState<string>(localStorage.getItem("authToken") || "");
  const [refreshToken, setRefreshToken] = useState<string>(
    localStorage.getItem("refreshToken") || "",
  );
  const [userStr, setUserStr] = useState<string>(localStorage.getItem("user") || "");
  const [isSubscribed, setIsSubscribed] = useState<null | boolean>(null);

  // UI specific settings, consider separating out from AuthContext
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const googleButtonType = isMobile ? "icon" : "standard";
  const googleButtonSize = isMobile ? "medium" : "large";

  // const googleSignInButton = document.getElementById(googleSignInButtonId) as HTMLElement;
  const google = window.google;

  useEffect(() => {
    if (google !== undefined) {
      google.accounts.id.initialize({
        client_id: googleClientId,
        callback: handleGoogleCallbackResponse,
      });

      !userStr &&
        google.accounts.id.renderButton(
          document.getElementById(googleSignInButtonId) as HTMLElement,
          {
            type: googleButtonType,
            theme: "outline",
            size: googleButtonSize,
          },
        );

      !userStr && google.accounts.id.prompt();
    }
  }, [userStr, google]);

  useEffect(() => {
    const getData = async () => {
      const getSubscriptionStatus = api.get(ENDPOINTS.stripeSubscriptionStatus);
      const subStatusResp = await getSubscriptionStatus;
      const subStatus = subStatusResp as ISubscriptionStatus;
      setIsSubscribed(subStatus.status === "active" ? true : false);
    };
    userStr ? getData() : setIsSubscribed(false);
  }, []);

  const handleGoogleCallbackResponse = async (response: google.accounts.id.CredentialResponse) => {
    return await api
      .post(ENDPOINTS.googleLogin, { token: response.credential })
      .then((response) => {
        const token = response as IAuthToken;
        localStorage.setItem("authToken", token.access_token);
        localStorage.setItem("refreshToken", token.refresh_token);
        setAuthToken(token.access_token);
        setRefreshToken(token.refresh_token);
      })
      .then(() => {
        return api.get(ENDPOINTS.me);
      })
      .then((response) => {
        const data = response as IUser;
        const dataStr = JSON.stringify(data);
        localStorage.setItem("user", dataStr);
        setUserStr(dataStr);
        // googleSignInButton.hidden = true;
        window.location.reload();
      });
  };

  const login = async (data: FormData) => {
    return await api
      .post(ENDPOINTS.login, data, true)
      .then((response) => {
        const token = response as IAuthToken;
        localStorage.setItem("authToken", token.access_token);
        localStorage.setItem("refreshToken", token.refresh_token);
        setAuthToken(token.access_token);
        setRefreshToken(token.refresh_token);
      })
      .then(() => {
        return api.get(ENDPOINTS.me);
      })
      .then((response) => {
        const data = response as IUser;
        const dataStr = JSON.stringify(data);
        localStorage.setItem("user", dataStr);
        setUserStr(dataStr);
        // googleSignInButton.hidden = true;
        window.location.reload();
      });
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    setAuthToken("");
    setRefreshToken("");
    localStorage.removeItem("user");
    setUserStr("");
  };

  const value = {
    authToken,
    setAuthToken,
    refreshToken,
    setRefreshToken,
    login,
    logout,
    handleGoogleCallbackResponse,
    isSubscribed,
  };
  // const value = { authToken, setAuthToken, login, logout };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const RequireAuth = ({
  redirectPath,
  children,
}: {
  redirectPath: string;
  children?: JSX.Element;
}) => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("authToken") !== null;

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }
  return children ? children : <Outlet />;
};
