import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IChannelListItem } from "apis/schema/youtube";
import { ChannelDetailsModal } from "components/ChannelHeader/ChannelDetailsModal";
import React, { useState } from "react";
import { COLORS } from "themes/theme";

interface ChannelHeaderProps {
  channel: IChannelListItem;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  margin: theme.spacing(2),
}));

const ChannelHeader: React.FC<ChannelHeaderProps> = ({ channel }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const maxDescriptionWordLength = 50;
  const channelDescription = channel.snippet?.description || "";
  const words = channelDescription.split(" ");
  const displayText = isTruncated
    ? words.slice(0, maxDescriptionWordLength).join(" ")
    : channelDescription;
  // const displayText = isTruncated
  //   ? channelDescription.slice(0, maxDescriptionWordLength)
  //   : channelDescription;

  return (
    <Stack alignItems="center" spacing={1}>
      {/* <Box
        sx={{
          backgroundImage: `url(${channel.brandingSettings?.image?.bannerExternalUrl})`,
          // backgroundImage: `url("https://yt3.googleusercontent.com/RRTJSt2V5kx5zCXcgVKBOjuEg5HNYgxD37vaGQa7FzVK1OOmJ4jV-_VqDpG0Q2uaU6N7iIrkBQ=w2120-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 200,
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      /> */}
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Box
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <StyledAvatar
              alt={channel.snippet?.title}
              src={channel.snippet?.thumbnails.high?.url}
              onClick={() => window.open(`https://www.youtube.com/channel/${channel.id}`, "_blank")}
              sx={{ cursor: "pointer" }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="h6" align="center" mb={1}>
            {channel.snippet?.title}
          </Typography>
          <Typography variant="body2" align="justify" mb={1}>
            {displayText}
            {words.length > maxDescriptionWordLength && (
              <>
                {isTruncated ? "..." : ""}
                <Typography
                  fontSize={14}
                  color={COLORS.link}
                  component="span" // Set component to "span" to avoid nesting issues
                  onClick={toggleTruncate}
                  style={{ cursor: "pointer" }}>
                  {isTruncated ? " Read more" : " Read less"}
                </Typography>
              </>
            )}
          </Typography>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography
              fontSize={14}
              color={COLORS.link}
              onClick={handleOpenModal}
              style={{ cursor: "pointer" }}>
              Channel details
            </Typography>
          </Box>
        </Box>
      </Box>
      <ChannelDetailsModal open={isModalOpen} onClose={handleCloseModal} data={channel} />
    </Stack>
  );
};

export { ChannelHeader };
