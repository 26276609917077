export enum ChatUserType {
  ai = "ai",
  human = "human",
}
export interface IChatSend {
  text: string;
  sessionId?: string;
  model?: string;
}
export interface IChatSession {
  sessionId: string;
  updatedAt: string;
  chatHistory: IChatHistory[];
}

export interface IChatResponse extends IChatSession {
  userId: string;
  answer: string;
  question?: string;
}

export interface IChatHistory {
  content: string;
  type: string;
  // example: boolean;
  // is_chunk: boolean;
  additional_kwargs: {
    created_at: string;
    rating?: number;
  };
}

export enum ChatMessageRatingEnum {
  Up = 1,
  Down = -1,
  Neutral = 0,
}
