export const COLORS = {
  primary: "#083470",
  primaryLight: "#395C8C",
  primaryDark: "#05244E",
  primaryDisabled: "#E3DBFB",
  secondary: "#F1C622",
  secondaryLight: "#F3D14E",
  secondaryDark: "#A88A17",
  background: "#F9FAFB",
  backgroundDark: "#000000",
  backgroundGrey: "#424949",
  text: "#161E2E",
  textSecondary: "#6B7280",
  textDisabled: "#4B5563",
  textRed: "#F67984",
  disabled: "#9FA6B2",
  link: "#5592EE",
};

export const WEIGHTS = {
  bold: 700,
  thick: 500,
  normal: 400,
  thin: 100,
};

export const FONTSIZE = {
  xxxsmall: 8,
  xxsmall: 10,
  xsmall: 13,
  small: 14,
  normal: 16,
  large: 18,
  xlarge: 20,
  xxlarge: 24,
};

export const SPACING = {
  xsmall: 2,
  small: 4,
  base: 8,
  large: 16,
};

export const SHAPE = {
  radius: 12,
  circle: 999,
  rounded: 60,
};
