import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  ISemanticSearchGroupedResult,
  ISemanticSearchResult,
  MediaTypeEnum,
} from "apis/schema/core";
import SegmentText from "pages/Home/SegmentText";
import { useState } from "react";
import { theme } from "themes";
import { COLORS } from "themes/theme";

interface IRenderVideoResult {
  queryId: string | null;
  result: ISemanticSearchGroupedResult;
  handleSegmentClicked: (segment: ISemanticSearchResult) => void;
  mediaType: MediaTypeEnum;
  segmentLikeIds: string[];
}

const RenderVideoResult = ({
  queryId,
  result,
  handleSegmentClicked,
  mediaType,
  segmentLikeIds,
}: IRenderVideoResult) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const topSegment = result.matchedSegments[0];
  const thumbnail = topSegment.metadata.thumbnail;
  const title = topSegment.metadata.title;

  const visibleElements = isShowMore
    ? result.matchedSegments.slice(0, 10)
    : result.matchedSegments.slice(0, 2);
  const moreResults = result.matchedSegments.length > 2;

  return (
    <Box
      key={"videoResult" + result.videoId}
      display="flex"
      justifyContent={"left"}
      alignItems="start"
      sx={{ mb: 2 }}>
      {!isMobile && !isTablet && (
        <img src={thumbnail} style={{ width: "auto", height: "125px", paddingTop: "6px" }}></img>
      )}
      <Stack sx={{ ml: 2, width: "100%" }}>
        {(isMobile || isTablet) && (
          <Box display="flex" justifyContent={"center"} alignItems="center">
            <img
              src={thumbnail}
              style={{
                width: isMobile ? "70%" : isTablet ? "50%" : "auto",
                height: "auto",
              }}></img>
          </Box>
        )}
        <Typography fontWeight="800" color={COLORS.textRed} mb={1}>
          {title}
        </Typography>
        <Box
          sx={{
            p: 1,
            borderRadius: "0.5rem",
            background: COLORS.backgroundGrey,
            ...(isShowMore && {
              maxHeight: "350px",
              overflow: "auto",
              background: COLORS.backgroundGrey,
            }),
          }}>
          {visibleElements.map((s) => (
            <SegmentText
              key={s.id}
              queryId={queryId}
              handleSegmentClicked={handleSegmentClicked}
              mediaType={mediaType}
              segmentLikeIds={segmentLikeIds}
              segment={s}
            />
          ))}
        </Box>
        {moreResults && (
          <Box
            display={"flex"}
            justifyContent="flex-end"
            onClick={() => setIsShowMore(!isShowMore)}
            sx={{ cursor: "pointer", color: COLORS.link, mr: "1.25rem" }}>
            <Typography fontSize={14}>{isShowMore ? "Show less" : "Show more..."}</Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export { RenderVideoResult };
