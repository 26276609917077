import { createTheme } from "@mui/material/styles";
import { COLORS } from "./theme";

declare module "@mui/material/styles" {
  interface Theme {
    layout: {
      drawerWidth: number;
      // mobileDrawerWidth: number;
    };
    mainContent: {
      flexGrow: number;
      padding: string;
    };
    form: {
      textFieldBackgroundColor: string;
      textFieldLabelColor: string;
    };
  }
  interface ThemeOptions {
    layout?: {
      drawerWidth: number;
      // mobileDrawerWidth: number;
    };
    mainContent?: {
      flexGrow: number;
      padding: string;
    };
    form?: {
      textFieldBackgroundColor: string;
      textFieldLabelColor: string;
    };
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 350,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
    background: {
      default: COLORS.background,
    },
  },
  typography: {
    fontFamily: ["Open Sans", "Roboto", "Helvetica", "Arial"].join(","),
  },
  layout: {
    drawerWidth: 240,
    // mobileDrawerWidth: 120
  },
  mainContent: {
    flexGrow: 1,
    padding: "2rem 1.25rem 1.25rem 1.25rem",
  },
  form: {
    textFieldBackgroundColor: "#F8F8F8",
    textFieldLabelColor: "rgba(0, 0, 0, 0.87)",
  },
});

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 350,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: COLORS.primaryDark,
    },
    secondary: {
      main: COLORS.secondary,
    },
    background: {
      default: COLORS.backgroundDark,
    },
  },
  typography: {
    fontFamily: ["Open Sans", "Roboto", "Helvetica", "Arial"].join(","),
  },
  layout: {
    drawerWidth: 240,
    // mobileDrawerWidth: 120
  },
  mainContent: {
    flexGrow: 1,
    padding: "2rem 1.25rem 1.25rem 1.25rem",
  },
  form: {
    textFieldBackgroundColor: "#F8F8F8",
    textFieldLabelColor: "#F8F8F8",
  },
});

export { darkTheme, theme };
