import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { ChatUserType } from "apis/schema/chat";
import React from "react";
import { theme } from "themes";

interface Source {
  index: number;
  url: string;
  title: string;
  publishedAt: string;
}

const extractSummary = (text: string): string => {
  const summaryPattern = /(.*)\n\nSources:/s;
  const summaryMatch = text.match(summaryPattern);
  return summaryMatch ? summaryMatch[1].trim() : text;
};

const parseSources = (text: string): Source[] => {
  const sourcePattern = /\[(\d+)\]: URL: (.*?), Title: (.*?), Published at: (.*?)/g;
  const sources: Source[] = [];
  let match: RegExpExecArray | null;

  while ((match = sourcePattern.exec(text)) !== null) {
    sources.push({
      index: parseInt(match[1]),
      url: match[2],
      title: match[3],
      publishedAt: match[4],
    });
  }
  return sources;
};

const parseYouTubeUrl = (url: string) => {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[1].length == 11 ? match[1] : false;
};

const getIframeSrc = (url: string) => {
  const videoId = parseYouTubeUrl(url);
  const startTimeMatch = url.match(/t=(\d+)/);
  const start = startTimeMatch ? startTimeMatch[1] : "0";
  return videoId ? `https://www.youtube.com/embed/${videoId}?controls=1&rel=0&start=${start}` : "";
};

const ChatRowWithYouTubeSource: React.FC<{
  text: string;
  maxPerRow: number;
  userType: ChatUserType;
}> = ({ text, maxPerRow, userType }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const sources = parseSources(text);
  const gridItemSize = Math.floor(12 / maxPerRow);
  const minHeight = isMobile ? 125 : isTablet ? 225 : 300;

  return (
    <div>
      <Typography
        color={userType === ChatUserType.ai ? "white" : "black"}
        mb={2}
        whiteSpace="pre-wrap">
        {extractSummary(text)}
      </Typography>
      <Grid container spacing={2}>
        {sources.map((source, index) => (
          <Grid item xs={gridItemSize} key={index}>
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              height="100%"
              width="100%">
              <Typography color={"white"} mb={1}>
                [{source.index}] {source.title}
              </Typography>
              <div style={{ width: "100%" }}>
                <iframe
                  src={getIframeSrc(source.url)}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                  style={{
                    border: "3px solid #A88A17",
                    borderRadius: "0.5rem",
                    width: "100%",
                    minHeight: minHeight,
                  }}
                />
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export { ChatRowWithYouTubeSource };
