import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Box, IconButton, Typography } from "@mui/material";
import { ENDPOINTS } from "apis";
import { ISemanticSearchResult, MediaTypeEnum } from "apis/schema/core";
import { useEffect, useState } from "react";
import api from "shared/utils/api";
import { COLORS } from "themes/theme";

type SegmentTextProps = {
  queryId: string | null;
  handleSegmentClicked: (segment: ISemanticSearchResult) => void;
  mediaType: MediaTypeEnum;
  segmentLikeIds: string[];
  segment: ISemanticSearchResult;
};

const SegmentText = ({
  queryId,
  handleSegmentClicked,
  mediaType,
  segmentLikeIds,
  segment,
}: SegmentTextProps) => {
  const [isSegmentLiked, setIsSegmentLiked] = useState(false);
  const timestamp = new Date(segment.metadata.startSecond * 1000).toISOString().substring(11, 19);
  const handleLike = (segment: ISemanticSearchResult) => {
    // TODO: remove temp patch
    const metadata = segment.metadata;
    metadata.id = metadata.videoId;
    api
      .post(ENDPOINTS.likeSegment, {
        segmentId: segment.id,
        queryHistoryId: queryId,
        segmentMetadata: metadata,
        mediaTypeSlug: mediaType,
      })
      .then((res) => {
        const response = res as { liked: boolean };
        if (response.liked === true) {
          setIsSegmentLiked(true);
        } else {
          setIsSegmentLiked(false);
        }
      });
  };
  useEffect(() => {
    segmentLikeIds.includes(segment.id) ? setIsSegmentLiked(true) : setIsSegmentLiked(false);
  }, []);

  return (
    <Box display="flex" justifyContent={"center"}>
      <Typography
        fontSize={14}
        sx={{
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          // display: "-webkit-box",
          // WebkitLineClamp: "4",
          // WebkitBoxOrient: "vertical",
          mb: 0.5,
        }}
        align="justify">
        <b
          style={{ color: COLORS.link, cursor: "pointer" }}
          onClick={() => handleSegmentClicked(segment)}>
          @{timestamp}{" "}
        </b>{" "}
        {segment.metadata.text}
      </Typography>
      {isSegmentLiked ? (
        <IconButton onClick={() => handleLike(segment)}>
          <FavoriteIcon sx={{ color: COLORS.textRed }} />
        </IconButton>
      ) : (
        <IconButton onClick={() => handleLike(segment)}>
          <FavoriteBorderIcon sx={{ color: COLORS.textRed }} />
        </IconButton>
      )}
    </Box>
  );
};

export default SegmentText;
