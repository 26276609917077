import { ENDPOINTS } from "apis";
import { IChatSession } from "apis/schema/chat";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "shared/contexts/Auth";
import { useSnack } from "shared/contexts/Snack";
import api from "shared/utils/api";

interface ChatSessionContextType {
  chatSessions: IChatSession[];
  currentChatSession: IChatSession | null;
  currentChatSessionMessageLength: number | undefined;
  setCurrentChatSession: (chatSession: IChatSession | null) => void;
  addChatSession: (chatSession: IChatSession) => void;
  deleteChatSession: (sessionId: string) => void;
  updateChatSession: (udpatedSession: IChatSession) => void;
  llmModel: string;
  changeLlmModel: (model: string) => void;
}

const ChatSessionContext = createContext<ChatSessionContextType | undefined>(undefined);

export const useChatSession = () => {
  const context = useContext(ChatSessionContext);
  if (!context) {
    throw new Error("useChatSession must be used within a ChatSessionProvider");
  }
  return context;
};

const ChatSessionProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const { snackError } = useSnack();
  const [chatSessions, setChatSessions] = useState<IChatSession[]>([]);
  const [currentChatSession, setCurrentChatSession] = useState<IChatSession | null>(null);
  const [llmModel, setLlmModel] = useState<string>("gpt-3.5-turbo");

  useEffect(() => {
    const getSessions = async () => {
      try {
        const sessionsPromise = api.get(ENDPOINTS.chatSessions);
        const sessions = (await sessionsPromise) as IChatSession[];
        setChatSessions(sessions);
      } catch (error) {
        snackError(error);
      }
    };
    if (!auth.isSubscribed) return;
    getSessions();
  }, []);

  const updateChatSession = (updatedSession: IChatSession) => {
    setChatSessions((prevChatSessions) =>
      prevChatSessions.map((session) =>
        session.sessionId === updatedSession.sessionId ? updatedSession : session,
      ),
    );
    setCurrentChatSession(updatedSession);
  };

  const addChatSession = (newSession: IChatSession) => {
    setChatSessions((prevChatSessions) => [newSession, ...prevChatSessions]);
  };

  const deleteChatSession = async (sessionId: string) => {
    try {
      const deletePromise = api.delete(`${ENDPOINTS.chatSessions}/${sessionId}`);
      await deletePromise;
      setCurrentChatSession(null);
    } catch (error) {
      snackError(error);
    }
    setChatSessions((prevChatSessions) =>
      prevChatSessions.filter((s) => s.sessionId !== sessionId),
    );
  };

  const changeLlmModel = (model: string) => {
    setLlmModel(model);
  };

  return (
    <ChatSessionContext.Provider
      value={{
        chatSessions,
        currentChatSession,
        currentChatSessionMessageLength: currentChatSession?.chatHistory.length,
        setCurrentChatSession,
        addChatSession,
        deleteChatSession,
        updateChatSession,
        llmModel,
        changeLlmModel,
      }}>
      {children}
    </ChatSessionContext.Provider>
  );
};

export { ChatSessionProvider };
