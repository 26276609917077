import { Alert, AlertColor, Snackbar, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { FC } from "react";
import { Outlet } from "react-router-dom";

import { useSnack } from "shared/contexts/Snack";
import { AppBar } from "../components/AppBar";

const MainLayout: FC = () => {
  const { isShowingSnack, forceCloseSnack, snackMessage, snackSeverity } = useSnack();
  return (
    <>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          maxWidth={1440}
          margin={"0 auto"}
          mb={2}>
          <AppBar />
          <Box
            sx={{
              flexGrow: 1,
              pl: { xs: 2, xsm: 3, md: 4 },
              pr: { xs: 2, xsm: 3, md: 4 },
              maxWidth: 1420,
              width: "100vw",
              // overflowX: "auto",
            }}>
            <Outlet />
          </Box>
        </Stack>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isShowingSnack}
          autoHideDuration={6000}
          onClose={forceCloseSnack}
          message={snackMessage}>
          <Alert
            onClose={forceCloseSnack}
            severity={snackSeverity as unknown as AlertColor}
            sx={{ width: "100%" }}>
            {snackMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export { MainLayout };
