import { ENDPOINTS } from "apis";
import axios, { AxiosError, RawAxiosRequestHeaders } from "axios";
import { jwtDecode } from "jwt-decode";

export const isAuthValid = (): boolean => {
  const authToken = localStorage.getItem("authToken");
  if (authToken == null) return false;
  const decodedToken: any = jwtDecode(authToken);
  if (decodedToken == null) return false;
  const now = new Date();
  if (decodedToken?.exp * 1000 < now.getTime()) return false;
  return true;
};
export const isRefreshTokenValid = (): boolean => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken == null) return false;
  const decodedToken: any = jwtDecode(refreshToken);
  if (decodedToken == null) return false;
  const now = new Date();
  if (decodedToken?.exp * 1000 < now.getTime()) return false;
  return true;
};

const defaults = {
  headers: (isForm: boolean) => {
    const authToken = localStorage.getItem("authToken");
    const contentType = isForm ? "multipart/form-data" : "application/json";
    let headers: RawAxiosRequestHeaders = { "Content-Type": contentType };
    if (authToken) {
      headers = {
        "Content-Type": contentType,
        Authorization: `Bearer ${authToken}`,
      };
    }
    return headers;
  },
  error: {
    code: "INTERNAL_ERROR",
    message: "Something went wrong. Please check your internet connection or contact our support.",
    status: 503,
    data: {},
  },
};

const checkAuth = async () => {
  if (localStorage.getItem("authToken") !== null) {
    if (isAuthValid() === false) {
      if (isRefreshTokenValid() === false) {
        // Remove auth token and redirect to login page
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        // Reload page to redirect to login page if authToken is expired
        window.location.href = "/";
      } else {
        // Refresh auth token
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          await axios({
            url: ENDPOINTS.refreshToken,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: { token: refreshToken },
          }).then(
            (response) => {
              localStorage.setItem("authToken", response.data.access_token);
              localStorage.setItem("refreshToken", response.data.refresh_token);
            },
            (error) => {
              // Remove auth token and redirect to login page
              console.log(`Auth Error ${error}`);
              localStorage.removeItem("authToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("user");
              // Reload page to redirect to login page if authToken is expired
              window.location.href = "/";
            },
          );
        }
      }
    }
  }
};

const getHeaders = (url: string, isForm: boolean) => {
  if (url.startsWith(ENDPOINTS.publicAssetsBaseURL)) {
    return {
      "Content-Type": "application/json",
    };
  }
  return defaults.headers(isForm);
};

const api = async (method: string, url: string, variables?: any, isForm = false) => {
  await checkAuth();
  const headers = getHeaders(url, isForm);

  return new Promise((resolve, reject) => {
    axios({
      // url: `${defaults.baseURL}${url}`,
      url: url,
      method: method,
      headers: headers,
      params: method === "get" ? variables : undefined,
      data: method !== "get" ? variables : undefined,
      // paramsSerializer: (params) =>
      //   Qs.stringify(params, { arrayFormat: "brackets" }),
    }).then(
      (response) => {
        resolve(response.data);
      },
      (error: AxiosError) => {
        if (error.response) {
          reject(error.response);
        } else {
          reject(defaults.error);
        }
        // TODO: Custom error messages
        // if (error.response) {
        //   if (error.response.data.error.code === 'INVALID_TOKEN') {
        //     removeAuthToken();
        //     navigate('/login');
        //   } else {
        //     reject(error.response.data.error);
        //   }
        // } else {
        //   reject(defaults.error);
        // }
      },
    );
  });
};

export default {
  get: (url: string, variables?: any) => api("get", url, variables),
  post: (url: string, variables?: any, isForm = false) => api("post", url, variables, isForm),
  put: (url: string, variables?: any) => api("put", url, variables),
  patch: (url: string, variables?: any) => api("patch", url, variables),
  delete: (url: string, variables?: any) => api("delete", url, variables),
};
