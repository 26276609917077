import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ENDPOINTS } from "apis";
import { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { googleClientId, useAuth } from "shared/contexts/Auth";

import { useSnack } from "shared/contexts/Snack";
import api from "shared/utils/api";
import { theme } from "themes";
import { COLORS } from "themes/theme";

const Pricing: React.FC = () => {
  const auth = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const google = window.google;
  const googleSignInButtonIdPricingPage = "pricingGoogleSignInButton";

  const { snackError } = useSnack();

  const handleSubscribeClick = async () => {
    try {
      // TODO: use product as ENUM instead of hardcoded string
      const resp = await api.post(ENDPOINTS.stripeCheckout, { product: "subscription" });
      const data = resp as any;
      window.location.href = data.sessionUrl;
    } catch (error) {
      snackError((error as AxiosResponse).data.detail);
    }
    // TODO: change to using stripe package and parse sessionId
    // window.location.href = data.sessionId
  };

  useEffect(() => {
    if (google !== undefined) {
      google.accounts.id.initialize({
        client_id: googleClientId,
        callback: auth.handleGoogleCallbackResponse,
      });

      !auth.authToken &&
        google.accounts.id.renderButton(
          document.getElementById(googleSignInButtonIdPricingPage) as HTMLElement,
          {
            type: "standard",
            theme: "outline",
            size: "large",
          },
        );

      !auth.authToken && google.accounts.id.prompt();
    }
  }, [auth.authToken, google]);

  return (
    <Container maxWidth="md" sx={{ marginTop: "2rem" }}>
      <Typography variant="h3" align="center" gutterBottom>
        A Tool for Learners
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={5}>
          <Card
            sx={{
              textAlign: "left",
              color: "black",
              borderRadius: "10px",
              padding: "2rem 1rem",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#f0f0f0",
              ...(!isMobile && {
                height: "550px",
              }),
            }}>
            <CardContent>
              <Typography variant="h5" sx={{ marginBottom: "1rem", fontWeight: "bold" }}>
                Free Tier
              </Typography>
              <Typography component="div" variant="h5" display="flex" alignItems={"end"} gap={1}>
                <Box fontWeight="bold" display="inline">
                  £0
                </Box>
                <Typography color="grey">/ mo</Typography>
              </Typography>

              <Typography gutterBottom>For casual use</Typography>

              <Box component="ul" sx={{ padding: 0, marginTop: "1rem" }}>
                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon color="secondary" sx={{ mr: 2 }} /> 10 searches per day
                </Box>

                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon color="secondary" sx={{ mr: 2 }} /> 2 channels search at a time
                </Box>
                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon color="secondary" sx={{ mr: 2 }} /> Access to a few of the most
                  popular channels
                </Box>
                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon color="secondary" sx={{ mr: 2 }} />
                  Display up to 20 results per query
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    "&:disabled": {
                      background: COLORS.disabled,
                      color: COLORS.textDisabled,
                    },
                    ...(auth.authToken
                      ? { mt: "1rem", padding: "0.5rem 2rem" }
                      : { padding: "0.5rem 2rem" }),
                  }}
                  disabled>
                  {auth.authToken ? "Signed In" : "Sign in to access"}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Card
            sx={{
              textAlign: "left",
              color: "black",
              borderRadius: "10px",
              padding: "2rem 1rem",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              backgroundColor: COLORS.secondary,
              ...(!isMobile && {
                height: "550px",
              }),
            }}>
            <CardContent>
              <Typography variant="h5" sx={{ marginBottom: "1rem", fontWeight: "bold" }}>
                Premium
              </Typography>
              <Typography component="div" variant="h5" display="flex" alignItems={"end"} gap={1}>
                <Box fontWeight="bold" display="inline">
                  £4.99
                </Box>
                <Typography color="grey">/ mo</Typography>
              </Typography>

              <Typography gutterBottom>For advance learners</Typography>
              <Box component="ul" sx={{ padding: 0, marginTop: "1rem" }}>
                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon sx={{ mr: 2 }} /> Unlimited Search
                </Box>

                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon sx={{ mr: 2 }} /> Multiple channels search
                </Box>
                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon sx={{ mr: 2 }} /> Access to 70+ most popular channels
                </Box>
                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon sx={{ mr: 2 }} />
                  Enhanced results, display up to 50 results per query
                </Box>
                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon sx={{ mr: 2 }} />
                  Chat with YouTube channels
                </Box>
                <Box
                  component="li"
                  sx={{ marginBottom: "0.5rem", display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon sx={{ mr: 2 }} />
                  Chrome Extension for YouTube
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {!auth.authToken ? (
                  <Box>
                    <div id={googleSignInButtonIdPricingPage} data-client_id={googleClientId}></div>
                  </Box>
                ) : (
                  <Button
                    onClick={handleSubscribeClick}
                    variant="contained"
                    sx={{
                      marginTop: "1rem",
                      padding: "0.5rem 2rem",
                      fontWeight: "bold",
                      background: "primary",
                      "&:disabled": {
                        background: COLORS.disabled,
                        color: COLORS.textDisabled,
                      },
                      "&:hover": {
                        opacity: "0.9",
                      },
                    }}
                    disabled={auth.isSubscribed === null ? true : auth.isSubscribed}>
                    {auth.isSubscribed ? "Subscribed ✓" : "Get Started"}
                  </Button>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export { Pricing };
