import { Avatar, Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { ENDPOINTS } from "apis";
import { IUser } from "apis/schema/core";
import { ISubscriptionStatus } from "apis/schema/stripe";
import { QueryHistory } from "components/QueryHistory";
import { SubscriptionStatus } from "components/SubscriptionStatus";
import { useEffect, useState } from "react";
import api from "shared/utils/api";
import { theme } from "themes";

const Profile = () => {
  const [me, setMe] = useState<IUser>();
  const [subStatus, setSubStatus] = useState<ISubscriptionStatus>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const getData = async () => {
      const getUser = api.get(ENDPOINTS.me);
      const getSubscriptionStatus = api.get(ENDPOINTS.stripeSubscriptionStatus);

      const userProfile = (await getUser) as IUser;
      const data = (await getSubscriptionStatus) as ISubscriptionStatus;
      setMe(userProfile);
      setSubStatus(data);
    };
    getData();
  }, []);

  const subscriptionStatus = (
    <SubscriptionStatus
      status={subStatus ? subStatus.status : "inactive"}
      currentPeriodStart={subStatus?.currentPeriodStart}
      currentPeriodEnd={subStatus?.currentPeriodEnd}
    />
  );

  return (
    <>
      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Box>
            <Avatar
              src={me?.picture}
              sx={{
                fontSize: "2.5rem",
                margin: "0 auto",
                borderRadius: "1rem",
                width: "5rem",
                height: "5rem",
                marginTop: "1.4rem",
              }}
            />
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "1.2rem",
                fontWeight: "bold",
                lineHeight: "2.1rem",
                margin: ".6rem auto",
              }}>
              {me?.firstName} {me?.lastName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          {!isMobile && subStatus?.status === "active" && subscriptionStatus}
        </Grid>
      </Grid>
      <Stack gap={2}>
        <Box>
          <Box sx={{ mb: 2 }}>
            {isMobile && subStatus?.status === "active" && subscriptionStatus}
          </Box>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            Query History
          </Typography>
          <QueryHistory />
        </Box>
      </Stack>
    </>
  );
};

export { Profile };
