import { MainLayout } from "layouts/MainLayout";
import { ChannelPage } from "pages/Channels";
// import { ChatHome } from "pages/Chat";
import { YouTubeChannelChat } from "pages/Chat/YouTubeChannelChat";
import { ChromeExtensionAuth } from "pages/ChomeExtensionAuth";
import { Home } from "pages/Home";
import { Pricing } from "pages/Pricing";
import { Privacy } from "pages/Privacy";
import { Profile } from "pages/Profile";
import { SearchPage } from "pages/Search";
import StripePaymentCancel from "pages/StripePayment/cancel";
import StripePaymentSuccess from "pages/StripePayment/success";
// import Subscription from "pages/Subscription";
import { TermsAndConditions } from "pages/TermsAndConditions";
// import { YouTube } from "pages/YouTube";
// import { YouTubeProcessStatus } from "pages/YouTube/ProcessStatus";
import { FC } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { RequireAuth } from "shared/contexts/Auth";
// import { BookSummary } from "pages/BookSummary";
// import { YouTubeSearch } from "pages/YouTubeSummary";
// import { MultiSummary } from "pages/YouTubeSummary/MultiSummary";
// import { Login } from "pages/Login";
// import { Register } from "pages/Register";
// import YouTubeSummary from "pages/YouTubeSummary/Summary";

export enum PATHS {
  home = "/",
  login = "/login",
  register = "/register",
  profile = "/profile",
  bookSummary = "/book-summary",
  youTube = "/youtube",
  youTubeProcessStatus = "/youtube-process-status",
  youTubeSearch = "/youtube-search",
  youTubeSummary = "/youtube-summary",
  youTubeMultiSummary = "/youtube-multi-summary",
  chromeExtensionTask = "/chrome-extension-task",
  cs50 = "/cs50",
  podcast = "/podcast",
  imperial = "/imperial",
  notionConnect = "/notion-connect",
  chromeExtensionConnect = "/chrome-extension-connect",
  privacy = "/privacy",
  termsAndConditions = "/terms-and-conditions",
  stripePaymentSuccess = "/payment-success",
  stripePaymentCancelled = "/payment-cancelled",
  chat = "/chat",
  chatYouTube = "/chat/:channelHandle",
  channels = "/channels",
  search = "/search",
  pricing = "/pricing",
}

const MainRoutes: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={PATHS.home} element={<MainLayout />}>
          <Route path={PATHS.home} element={<Home />}></Route>
          <Route path={PATHS.channels} element={<ChannelPage />}></Route>
          <Route path={PATHS.search} element={<SearchPage />}></Route>
          {/* <Route path={PATHS.login} element={<Login />}></Route>
          <Route path={PATHS.register} element={<Register />}></Route>
          <Route path={PATHS.cs50} element={<CS50 />}></Route>
          <Route path={PATHS.podcast} element={<Podcast />}></Route>
          <Route path={PATHS.imperial} element={<Imperial />}></Route> */}
          <Route element={<RequireAuth redirectPath={PATHS.home} />}>
            <Route path={PATHS.profile} element={<Profile />}></Route>
            <Route path={PATHS.stripePaymentSuccess} element={<StripePaymentSuccess />}></Route>
            <Route path={PATHS.stripePaymentCancelled} element={<StripePaymentCancel />}></Route>
            {/* <Route path={PATHS.youTube} element={<YouTube />}></Route> */}
            {/* <Route path={PATHS.youTubeProcessStatus} element={<YouTubeProcessStatus />} /> */}
            {/* <Route path={PATHS.chromeExtensionTask} element={<ChromeExtensionTask />}></Route>
            <Route path={PATHS.notionConnect} element={<NotionConnect />}></Route> */}
            <Route path={PATHS.chromeExtensionConnect} element={<ChromeExtensionAuth />}></Route>
          </Route>
          {/* <Route path={PATHS.chat} element={<ChatHome />}></Route> */}
          <Route path={PATHS.chat} element={<ChannelPage />}></Route>
          <Route path={PATHS.chatYouTube} element={<YouTubeChannelChat />}></Route>
          {/* <Route path={PATHS.bookSummary} element={<BookSummary />}></Route> */}
          {/* <Route path={PATHS.youTubeSearch} element={<YouTubeSearch />}></Route> */}
          {/* <Route path={PATHS.youTubeSummary} element={<YouTubeSummary />}></Route> */}
          {/* <Route path={PATHS.youTubeMultiSummary} element={<MultiSummary />}></Route> */}
          <Route path={PATHS.pricing} element={<Pricing />}></Route>
          <Route path={PATHS.privacy} element={<Privacy />}></Route>
          <Route path={PATHS.termsAndConditions} element={<TermsAndConditions />}></Route>
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
};

export { MainRoutes };
