import { Box, Button, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IYouTubeChannel } from "apis/schema/core";
import { CHANNELS_INFO } from "pages/Channels/channels";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "routings";
import { useAuth } from "shared/contexts/Auth";
import { theme } from "themes";
import { COLORS } from "themes/theme";

const RadiantText = styled(Typography)(() => ({
  "&": {
    background: "radial-gradient(50% 50% at 50% 50%, #FFD700 23.96%, #F67984 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
}));

const ChannelPage = () => {
  document.title = "Synthesis YouTube";
  const auth = useAuth();
  const CHANNELS_LIMIT = 100;
  const [channels, setChannels] = useState<IYouTubeChannel[]>([]);
  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;
  const isChat = pathname === PATHS.chat;

  const handleSelectedChannels = (channelId: string) => {
    if (selectedChannelIds.includes(channelId)) {
      const updatedElements = selectedChannelIds.filter((e) => e !== channelId);
      setSelectedChannelIds(updatedElements);
    } else if (selectedChannelIds.length <= CHANNELS_LIMIT) {
      setSelectedChannelIds([...selectedChannelIds, channelId]);
    }
  };

  // Fetch channel data from CHANNELS_INFO object
  useEffect(() => {
    const channelsData = Object.entries(CHANNELS_INFO).map(([channelHandle, channelInfo]) => {
      return {
        id: channelInfo.id,
        handle: channelHandle,
        title: channelInfo.title,
        thumbnail: channelInfo.thumbnail,
        category: channelInfo.category,
      };
    }) as IYouTubeChannel[];
    setChannels(channelsData);
  }, []);

  const isLimitReached = selectedChannelIds.length >= CHANNELS_LIMIT;

  const [selectedCategory, setSelectedCategory] = useState("Personal Development");
  const categories = [
    "Personal Development",
    "Business & Finance",
    "Science & Education",
    "Health & Wellness",
  ];

  const handleStartSearch = () => {
    !auth.isSubscribed
      ? navigate(PATHS.pricing)
      : navigate(PATHS.search, { state: { selectedChannelIds } });
  };
  const handleChatClick = (channelHandle?: string) => {
    if (!channelHandle) return;
    !auth.isSubscribed
      ? navigate(PATHS.pricing)
      : navigate(PATHS.chatYouTube.replace(":channelHandle", channelHandle));
  };

  const searchButton = (
    <Box width={"100%"} display="flex" justifyContent={"center"} alignItems={"center"} mt={4}>
      <Button
        onClick={handleStartSearch}
        variant="contained"
        sx={{
          padding: "0.5rem 3rem",
          maxWidth: "80%",
          color: "black",
          fontWeight: "bold",
          textTransform: "none",
          background:
            "conic-gradient(from 180deg at 50% 50%, #F67984 0deg, #FFD700 180deg, #F67984 360deg)",
          "&:disabled": {
            opacity: "0.6",
          },
          "&:hover": {
            opacity: "0.9",
          },
        }}
        disabled={selectedChannelIds.length === 0}>
        Start Search
      </Button>
    </Box>
  );

  const searchButtonFooter = (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        background: COLORS.backgroundDark,
      }}>
      <Box sx={{ mb: 4 }}>{searchButton}</Box>
    </Box>
  );

  return (
    <>
      <Stack mb={10} direction="column" justifyContent="center" alignItems="center" spacing={10}>
        <Stack justifyContent="center" alignItems="center">
          <Typography align="center">A Tool for Learners</Typography>
          <Box sx={{ my: 3, width: "250px", height: "54px" }}>
            <a
              href="https://www.producthunt.com/posts/synthesis-youtube?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-synthesis-youtube"
              target="_blank"
              rel="noreferrer">
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=379408&theme=light&period=daily"
                alt="Synthesis Youtube - Search video podcasts using GPT3 and get relevant segments | Product Hunt"
                width="250"
                height="54"
              />
            </a>
          </Box>
          <RadiantText variant="h4" fontWeight={900} mb={2} align="center">
            Synthesis YouTube
          </RadiantText>
          <Typography align="center">
            Choose the YouTube channels you want to search and enjoy relevant video segments in
            seconds 🔥
          </Typography>
        </Stack>
        <Stack
          mb={10}
          direction="row" // Changed to 'row' for side-by-side layout
          justifyContent={isChat ? "center" : "space-between"} // Align items to the left and right
          alignItems={isChat ? "center" : "flex-start"} // Align items to the top
          spacing={10}
          sx={{ padding: "20px" }} // Added padding for better spacing
        >
          <Stack
            width={isMobile ? "90%" : "80%"}
            alignItems={isChat ? "center" : "start"}
            sx={{ minHeight: "400px" }}>
            <div style={{ marginBottom: "16px" }}>
              {categories.map((category) => (
                <Button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  variant="contained"
                  sx={{
                    margin: "4px",
                    backgroundColor: "#19456B",
                    color: "white",
                    borderRadius: "10px",
                    border:
                      selectedCategory === category
                        ? "4px solid #FFD700" // Yellow border when selected
                        : "none", // No border when not selected
                    "&:hover": {
                      backgroundColor:
                        selectedCategory === category
                          ? "4px solid #FFD700" // Yellow border when selected
                          : "none", // No border when not selected
                    },
                  }}>
                  <Typography fontWeight="bold">{category}</Typography>
                </Button>
              ))}
            </div>

            {isChat ? (
              <Grid container spacing={1}>
                {channels
                  .filter((channel) => channel.category === selectedCategory)
                  .map((c) => {
                    return (
                      <Grid key={c.id} item xs={isMobile ? 4 : 2}>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleChatClick(c.handle)}>
                          <img
                            src={c.thumbnail}
                            alt={c.title}
                            width="100%"
                            style={{ borderRadius: "0.4rem" }}></img>
                          <Typography>{c.title}</Typography>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            ) : (
              <Grid container spacing={1}>
                {channels
                  .filter((channel) => channel.category === selectedCategory)
                  .map((c) => {
                    return (
                      <Grid key={c.id} item xs={isMobile ? 4 : 2}>
                        <div
                          // style={selectedChannelIds.includes(c.id) ? {} : { opacity: 0.25 }}
                          style={
                            isLimitReached && !selectedChannelIds.includes(c.id)
                              ? { pointerEvents: "none", opacity: 0.25 }
                              : !selectedChannelIds.includes(c.id)
                              ? { opacity: 0.25, cursor: "pointer" }
                              : { cursor: "pointer" }
                          }
                          onClick={() => handleSelectedChannels(c.id)}>
                          <img
                            src={c.thumbnail}
                            alt={c.title}
                            width="100%"
                            style={
                              selectedChannelIds.includes(c.id)
                                ? { border: "4px solid #F67984", borderRadius: "1rem" }
                                : { borderRadius: "0.4rem" }
                            }></img>
                          <Typography>{c.title}</Typography>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Stack>
          {!isMobile && !isChat && (
            <Stack
              width={isMobile ? "90%" : "20%"}
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "500px" }}>
              {searchButton}
            </Stack>
          )}
        </Stack>
      </Stack>
      {isMobile && !isChat && searchButtonFooter}
    </>
  );
};

export { ChannelPage };
