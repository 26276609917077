import { ChromeExtensionId, ENDPOINTS } from "apis";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "shared/contexts/Auth";
import api from "shared/utils/api";

interface IChromeExtensionAuthProps {
  extensionId: string;
  authToken: string | null;
  refreshToken: string | null;
}

const ChromeExtensionAuth = () => {
  const auth = useAuth();
  const [msg, setMsg] = useState("Authenticating...");
  const navigate = useNavigate();

  const sendTokenToChromeExtension = ({
    extensionId,
    authToken,
    refreshToken,
  }: IChromeExtensionAuthProps) => {
    chrome.runtime.sendMessage(extensionId, { authToken, refreshToken }, (response) => {
      if (!authToken || !refreshToken) {
        console.log("No token to send");
        setMsg("Not authenticated, please sign in and try again.");
        return;
      }
      if (!response.success) {
        console.log("error sending message", response);
        setMsg("Chrome extension connection unsuccessful");
        return response;
      }
      console.log("Sucesss ::: ", response.message);
      setMsg("Chrome extension authenticated successfully, you can now close this tab.");
    });
  };

  useEffect(() => {
    if (!auth.authToken) {
      setMsg("Please login and refresh the page.");
      return;
    }
    api
      .get(ENDPOINTS.me)
      .then(() => {
        sendTokenToChromeExtension({
          extensionId: ChromeExtensionId,
          authToken: localStorage.getItem("authToken"),
          refreshToken: localStorage.getItem("refreshToken"),
        });
      })
      .catch((e) => {
        // TODO: test if this is working
        if (e.response) {
          if (e.response.status === 401) {
            navigate(0);
          }
        }
      });
  }, []);

  return <>{msg}</>;
};

export { ChromeExtensionAuth };
