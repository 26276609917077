import {
  Button,
  Link,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ENDPOINTS } from "apis";
import { IYouTubeChannel } from "apis/schema/core";
import { IQueryHistoryWithLikes } from "apis/schema/queryHistory";
import { CHANNELS_INFO } from "pages/Channels/channels";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "routings";
import { useAuth } from "shared/contexts/Auth";
import api from "shared/utils/api";
import { getChannelLink } from "shared/utils/youtube";

const QueryHistory = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [queries, setQueries] = useState<IQueryHistoryWithLikes[]>([]);
  const [youTubeChannels, setYouTubeChannels] = useState<IYouTubeChannel[]>([]);
  useEffect(() => {
    const getData = async () => {
      const getQueryHistory = api.get(ENDPOINTS.queryHistoryWithLikes);
      const queryHistories = (await getQueryHistory) as IQueryHistoryWithLikes[];

      const channelsData = Object.values(CHANNELS_INFO).map((channel) => ({
        id: channel.id,
        title: channel.title,
        thumbnail: channel.thumbnail,
        category: channel.category,
      })) as IYouTubeChannel[];

      setQueries(queryHistories);
      setYouTubeChannels(channelsData);
    };
    getData();
  }, []);

  const handleSearchChannelsClick = (channelIds: string[] | null) => {
    if (channelIds === null) return;
    // TODO: pass in state and pre-select channels on home page
    if (!auth.isSubscribed) {
      navigate(PATHS.home);
    } else {
      navigate(PATHS.search, { state: { selectedChannelIds: channelIds } });
    }
  };

  const renderRow = (queryHistory: IQueryHistoryWithLikes) => {
    const queryChannels = queryHistory.query.channelIds;
    const channelLinks =
      queryChannels &&
      queryChannels.map((id: string) => {
        const channel = youTubeChannels.find((c) => c.id === id);
        return (
          channel && (
            <ListItem key={channel.id} sx={{ px: 0 }}>
              <Link href={getChannelLink(channel.id)} color="secondary">
                {channel.title}
              </Link>
            </ListItem>
          )
        );
      });
    const segmentLinks = queryHistory.segmentLikes?.map((s) => {
      return (
        <Link key={s.segmentId} href={s.segmentMetadata.url} color="secondary">
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              my: "0.7rem",
            }}>
            {s.segmentMetadata.text}
          </Typography>
        </Link>
      );
    });

    return (
      <TableRow key={queryHistory.id}>
        <TableCell
          component="th"
          scope="row"
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          {queryHistory.query.query}
        </TableCell>
        <TableCell align="right">
          <List sx={{ p: 0 }}>{channelLinks}</List>
        </TableCell>
        <TableCell>
          <Button
            onClick={() => handleSearchChannelsClick(queryHistory.query.channelIds)}
            variant="contained"
            color="secondary"
            size="small"
            sx={{ maxWidth: "80%", textTransform: "none" }}>
            Search Again
          </Button>
        </TableCell>
        <TableCell align="left">{queryHistory.createdAt}</TableCell>
        <TableCell align="justify" sx={{ maxWidth: "15rem" }}>
          {segmentLinks}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Query</TableCell>
              <TableCell align="left">Channels</TableCell>
              <TableCell align="left">Search channels</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Liked segments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{queries.map((q) => renderRow(q))}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export { QueryHistory };
