import { Avatar, Box, Paper } from "@mui/material";
import { IChannelListItem } from "apis/schema/youtube";
import React from "react";
import { COLORS } from "themes/theme";

interface ChannelHeaderProps {
  channel: IChannelListItem;
}

const ChannelHeaderRow: React.FC<ChannelHeaderProps> = ({ channel }) => {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        display: "flex",
        alignItems: "center",
        p: 2,
        my: 2,
        borderRadius: 0,
        bgcolor: COLORS.backgroundDark,
        zIndex: 1,
      }}>
      <Avatar
        alt={channel.snippet?.title}
        src={channel.snippet?.thumbnails.high?.url}
        onClick={() => window.open(`https://www.youtube.com/channel/${channel.id}`, "_blank")}
        sx={{ cursor: "pointer" }}
      />
      <Box display="flex" alignItems="center" ml={2}>
        {channel.snippet?.title}
      </Box>
    </Paper>
  );
};

export { ChannelHeaderRow };
