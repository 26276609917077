import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ENDPOINTS } from "apis";
import {
  ISegmentLike,
  ISemanticSearchGroupedResponse,
  ISemanticSearchGroupedResult,
  ISemanticSearchResult,
  IYouTubeChannel,
  MediaTypeEnum,
} from "apis/schema/core";
import { RenderVideoResult } from "pages/Home/RenderVideoResult";
import { useEffect, useRef, useState } from "react";
import api from "shared/utils/api";
import { theme } from "themes";
// import { CHANNELS } from "./channels";

const RadiantText = styled(Typography)(() => ({
  "&": {
    background: "radial-gradient(50% 50% at 50% 50%, #FFD700 23.96%, #F67984 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
}));

const Home = () => {
  document.title = "Synthesis YouTube";
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const loadingScrollRef = useRef<null | HTMLDivElement>(null);
  const CHANNELS_LIMIT = 2;
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState<string>();
  const [isQueryTextFieldError, setIsQueryTextFieldError] = useState(false);
  const [queryText, setQueryText] = useState("");
  const [results, setResults] = useState<ISemanticSearchGroupedResult[]>([]);
  const [queryId, setQueryId] = useState<string | null>(null);
  // const channels = CHANNELS as IYouTubeChannel[];
  const [channels, setChannels] = useState<IYouTubeChannel[]>([]);
  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>([
    "UC2D2CMWXMOVWx7giW1n3LIg",
    "UCGq-a57w-aPwyi3pW7XLiHw",
  ]);
  const [iframeSrc, setIframeSrc] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [disableSearch, setDisableSearch] = useState(false);
  const [isHighDemand, setIsHighDemand] = useState(false);
  const [segmentLikeIds, setSegmentLikeIds] = useState<string[]>([]);

  const delayHighDemandMessage = async (delayMs: number) => {
    await new Promise((res) => setTimeout(res, delayMs));
    setIsHighDemand(true);
  };
  const validationFn = (text: string) => {
    if (text.length > 100) {
      setIsQueryTextFieldError(true);
      setErrMsg("Please enter less than 100 characters");
      return false;
    }
    if (text.length === 0) {
      setIsQueryTextFieldError(true);
      setErrMsg("Please enter your query.");
      return false;
    }
    if (selectedChannelIds.length === 0) {
      setErrMsg("Please select at least one channel.");
      return false;
    }
    return true;
  };
  const handleQueryTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryText(e.target.value);
    const validated = validationFn(e.target.value);
    if (!validated) return;
    setIsQueryTextFieldError(false);
    setErrMsg(undefined);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const validated = validationFn(queryText);
      if (!validated) return;
      setIsQueryTextFieldError(false);
      setErrMsg(undefined);
      handleSearch(queryText, selectedChannelIds);
    }
  };
  const handleSearch = (query: string, channelIds?: string[], videoIds?: string[]) => {
    if (loadingScrollRef.current) {
      loadingScrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setIsLoading(true);
    // TODO: If api call is quicker than 3000ms, isHighDemand message will be visible and not set to false
    delayHighDemandMessage(3000);
    setErrMsg(undefined);
    setDisableSearch(true);
    const body = {
      query: query,
      channelIds: channelIds,
      videoIds: videoIds,
    };
    // TEMP FIX:
    const user = localStorage.getItem("user");
    let searchEndpoint = ENDPOINTS.semanticSearchYouTube;
    if (user) {
      searchEndpoint = ENDPOINTS.semanticSearchYouTube;
    } else {
      searchEndpoint = ENDPOINTS.semanticSearchYouTubeGeneral;
    }
    api
      .post(searchEndpoint, body)
      .then((response) => {
        const responseData = response as ISemanticSearchGroupedResponse;
        setResults(responseData.results);
        setQueryId(responseData.queryId);
        setIsLoading(false);
        setIsHighDemand(false);
        setDisableSearch(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsHighDemand(false);
        console.log(error.data);
        setErrMsg("Due to high demand, we are currently at capacity. Please try again later.");
        setDisableSearch(false);
      });
  };

  const handleSelectedChannels = (channelId: string) => {
    if (selectedChannelIds.includes(channelId)) {
      const updatedElements = selectedChannelIds.filter((e) => e !== channelId);
      setSelectedChannelIds(updatedElements);
    } else if (selectedChannelIds.length <= CHANNELS_LIMIT) {
      setSelectedChannelIds([...selectedChannelIds, channelId]);
    }
  };

  const handleSegmentClicked = (segment: ISemanticSearchResult) => {
    if (isMobile || isTablet) {
      window.open(segment.metadata.url, "_blank");
      return;
    }
    const start = Math.round(segment.metadata.startSecond);
    const iframeLink = `https://www.youtube.com/embed/${segment.metadata.videoId}?controls=1&autoplay=1&rel=0&start=${start}`;
    setIframeSrc(iframeLink);
  };

  const handleSampleQuery = (sampleQuery: string, sampleChannelIds: string[]) => {
    if (loadingScrollRef.current) {
      loadingScrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setQueryText(sampleQuery);
    setSelectedChannelIds(sampleChannelIds);
    handleSearch(sampleQuery, sampleChannelIds);
  };

  useEffect(() => {
    if (results.length !== 0 && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [results]);

  useEffect(() => {
    api.get(`${ENDPOINTS.publicAssetsBaseURL}metadata/youtube_channels.json`).then((response) => {
      const data = response as IYouTubeChannel[];
      setChannels(data);
    });
  }, []);

  const isLimitReached = selectedChannelIds.length >= CHANNELS_LIMIT;

  // TODO: Don't fetch all
  useEffect(() => {
    if (localStorage.getItem("user") !== null) {
      api.get(ENDPOINTS.likedSegmentsAll).then((res) => {
        const likes = res as ISegmentLike[];
        setSegmentLikeIds(likes.map((l) => l.segmentId));
      });
    }
  }, []);

  return (
    <>
      <Stack mb={10} direction="column" justifyContent="center" alignItems="center" spacing={10}>
        <Stack justifyContent="center" alignItems="center">
          <Typography align="center">A Tool for Learners</Typography>
          <Box sx={{ my: 3, width: "250px", height: "54px" }}>
            <a
              href="https://www.producthunt.com/posts/synthesis-youtube?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-synthesis&#0045;youtube"
              target="_blank"
              rel="noreferrer">
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=379408&theme=light&period=daily"
                alt="Synthesis&#0032;Youtube - Search&#0032;video&#0032;podcasts&#0032;using&#0032;GPT3&#0032;and&#0032;get&#0032;relevant&#0032;segments | Product Hunt"
                width="250"
                height="54"
              />
            </a>
          </Box>
          <RadiantText variant="h4" fontWeight={900} mb={2} align="center">
            Synthesis YouTube
          </RadiantText>
          <Typography align="center">
            Search one of the YouTube channels and enjoy relevant video segments in seconds 🔥
          </Typography>
        </Stack>
        <Box sx={{ minWidth: "340px", width: "60%" }}>
          <TextField
            label="Enter query and select your channel(s)"
            type="search"
            error={isQueryTextFieldError}
            value={queryText}
            onChange={handleQueryTextChange}
            onKeyDown={handleKeyPress}
            InputLabelProps={{
              sx: {
                "&.MuiInputLabel-root": {
                  transform: `translate(50px, 1rem)`,
                },
                "&.Mui-focused": {
                  fontSize: 0,
                },
                color: "grey",
                fontSize: queryText.length > 0 ? 0 : 16,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              background: "white",
              borderRadius: "3rem",
              "& fieldset": {
                borderRadius: "3rem",
                top: 0,
              },
              input: { color: "black" },
              "& legend": { display: "none" },
            }}
          />
          <Typography color="red">{errMsg}</Typography>
        </Box>
        <Stack width={isMobile ? "90%" : "65%"} alignItems="start">
          <Typography fontWeight={800} mb={2.5}>
            Select up to 2 podcast channels you want to search through:
          </Typography>
          <Grid container spacing={1}>
            {channels.map((c) => {
              return (
                <Grid key={c.id} item xs={isMobile ? 4 : 2}>
                  <div
                    // style={selectedChannelIds.includes(c.id) ? {} : { opacity: 0.25 }}
                    style={
                      isLimitReached && !selectedChannelIds.includes(c.id)
                        ? { pointerEvents: "none", opacity: 0.25 }
                        : !selectedChannelIds.includes(c.id)
                        ? { opacity: 0.25, cursor: "pointer" }
                        : { cursor: "pointer" }
                    }
                    onClick={() => handleSelectedChannels(c.id)}>
                    <img
                      src={c.thumbnail}
                      alt={c.title}
                      width="100%"
                      style={
                        selectedChannelIds.includes(c.id)
                          ? { border: "4px solid #F67984", borderRadius: "1rem" }
                          : { borderRadius: "0.4rem" }
                      }></img>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <Box width={"100%"} display="flex" justifyContent={"center"}>
            <Button
              onClick={() => handleSearch(queryText, selectedChannelIds)}
              variant="contained"
              sx={{
                mt: 5,
                padding: "0.5rem 3rem",
                maxWidth: "80%",
                color: "black",
                fontWeight: "bold",
                textTransform: "none",
                background:
                  "conic-gradient(from 180deg at 50% 50%, #F67984 0deg, #FFD700 180deg, #F67984 360deg)",
                "&:disabled": {
                  opacity: "0.6",
                },
                "&:hover": {
                  opacity: "0.9",
                },
              }}
              disabled={
                selectedChannelIds.length === 0 || disableSearch || queryText.trim().length === 0
              }>
              Search
            </Button>
          </Box>
          {isLoading && (
            <Box sx={{ color: "#f6db6f", width: "100%" }}>
              <LinearProgress color="inherit" sx={{ mb: 1, width: "100%", mt: 5 }} />
            </Box>
          )}
          {isLoading && isHighDemand && (
            <Typography sx={{ mb: 2 }} textAlign="center">
              Fetching results...
            </Typography>
          )}
          <div ref={loadingScrollRef}></div>
          <Typography sx={{ mt: 2, fontWeight: 800 }}>Examples</Typography>
          <Typography
            color="#5592EE"
            onClick={() =>
              handleSampleQuery("cold exposure protocol", ["UC2D2CMWXMOVWx7giW1n3LIg"])
            }
            sx={{ cursor: "pointer" }}>
            Cold exposure protocol | Huberman Lab
          </Typography>
          <Typography
            color="#5592EE"
            onClick={() =>
              handleSampleQuery("how frequent should I do cold exposure", [
                "UC2D2CMWXMOVWx7giW1n3LIg",
              ])
            }
            sx={{ cursor: "pointer" }}>
            How frequent should I do cold exposure | Huberman Lab
          </Typography>
          <Typography
            color="#5592EE"
            onClick={() =>
              handleSampleQuery("What are the keys the happiness", ["UCGq-a57w-aPwyi3pW7XLiHw"])
            }
            sx={{ cursor: "pointer" }}>
            What are the keys to happiness | The Diary of A CEO
          </Typography>
          <Typography
            color="#5592EE"
            onClick={() =>
              handleSampleQuery("What’s an optimal morning routine", ["UC2D2CMWXMOVWx7giW1n3LIg"])
            }
            sx={{ cursor: "pointer" }}>
            What&apos;s an optimal morning routine | Huberman Lab
          </Typography>
          <Typography
            color="#5592EE"
            onClick={() =>
              handleSampleQuery("How to manage time more effectively ", [
                "UChfo46ZNOV-vtehDc25A1Ug",
              ])
            }
            sx={{ cursor: "pointer" }}>
            How to manage time more effectively | Deep Dive with Ali Abdaal
          </Typography>
          <Typography
            color="#5592EE"
            onClick={() =>
              handleSampleQuery("how to make better decisions", ["UCLtTf_uKt0Itd0NG7txrwXA"])
            }
            sx={{ cursor: "pointer" }}>
            How to make better decisions | The Knowledge Project Podcast
          </Typography>
        </Stack>
      </Stack>

      {results.length > 0 && (
        <Box mb={8}>
          <Grid container spacing={4}>
            <Grid item xs={isMobile || isTablet ? 12 : 6.5}>
              <Paper
                sx={{
                  maxHeight: "700px",
                  overflow: "auto",
                  borderRadius: "1.25rem",
                  padding: 3,
                }}>
                <Typography variant="h6" fontWeight={"bold"} textAlign="center" mb={4}>
                  Results
                </Typography>
                <Stack maxHeight={"500px"} overflow="auto" width="100%">
                  {results.map((r) => {
                    return (
                      <RenderVideoResult
                        key={r.videoId}
                        queryId={queryId}
                        result={r}
                        handleSegmentClicked={handleSegmentClicked}
                        mediaType={MediaTypeEnum.youtube}
                        segmentLikeIds={segmentLikeIds}
                      />
                    );
                  })}
                </Stack>
              </Paper>
            </Grid>
            {!isMobile && !isTablet && (
              <>
                <Grid item xs={5.5}>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center" padding={3}>
                    Watch now
                  </Typography>
                  <Box
                    display="inline-block"
                    alignItems="center"
                    justifyContent="center"
                    width="100%">
                    <div className="active">
                      <iframe
                        src={iframeSrc}
                        height="400px"
                        width="100%"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                        style={{ border: "3px solid #F67984" }}
                      />
                    </div>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}
      <div ref={scrollRef}></div>
    </>
  );
};

export { Home };
