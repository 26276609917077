import FaceTwoToneIcon from "@mui/icons-material/FaceTwoTone";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Avatar, Box, IconButton, Paper, Skeleton, Stack, useMediaQuery } from "@mui/material";
import { ChatMessageRatingEnum, ChatUserType } from "apis/schema/chat";
import synthesisLogoSmall from "assets/images/icon-32.png";
import { ChatRowWithYouTubeSource } from "components/ChatRow/ChatRowWithYouTubeSource";
import { theme } from "themes";
import { COLORS } from "themes/theme";

interface ChatRowProps {
  index?: number;
  userType: ChatUserType;
  text: string;
  isLoading?: boolean;
  rating?: ChatMessageRatingEnum;
  onRatingsClick: (rating: number, index?: number) => void;
  disableRating?: boolean;
}

const ChatRow = ({
  index,
  userType,
  text,
  isLoading,
  rating,
  onRatingsClick,
  disableRating,
}: ChatRowProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const rate = (
    <Box>
      <IconButton onClick={() => onRatingsClick(ChatMessageRatingEnum.Up, index)}>
        <ThumbUpIcon
          style={{ color: rating === ChatMessageRatingEnum.Up ? COLORS.secondaryLight : undefined }}
        />
      </IconButton>
      <IconButton onClick={() => onRatingsClick(ChatMessageRatingEnum.Down, index)}>
        <ThumbDownIcon
          style={{
            color: rating === ChatMessageRatingEnum.Down ? COLORS.secondaryLight : undefined,
          }}
        />
      </IconButton>
    </Box>
  );

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        my: 2,
        borderRadius: "0.8rem",
        bgcolor: userType === ChatUserType.ai ? COLORS.backgroundDark : COLORS.secondary,
      }}>
      <Stack direction={"row"} spacing={2} width="100%">
        {userType === ChatUserType.ai ? (
          <Avatar sx={{ p: 1 }} src={synthesisLogoSmall} />
        ) : (
          <Avatar sx={{ bgcolor: COLORS.secondary }}>
            <FaceTwoToneIcon />
          </Avatar>
        )}
        {isLoading && <Skeleton animation="wave" sx={{ width: "100%" }} />}
        {/* <Typography
          color={userType === ChatUserType.ai ? COLORS.text : "white"}
          style={{ whiteSpace: "pre-wrap" }}>
          {text}
        </Typography> */}
        <Box display="flex" alignItems="center">
          <ChatRowWithYouTubeSource text={text} maxPerRow={isMobile ? 1 : 2} userType={userType} />
        </Box>
        {!disableRating && !isLoading && userType === ChatUserType.ai && rate}
      </Stack>
    </Paper>
  );
};

export default ChatRow;
