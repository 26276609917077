import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ISubscriptionStatus } from "apis/schema/stripe";

const SubscriptionStatus = ({
  status,
  currentPeriodStart,
  currentPeriodEnd,
}: ISubscriptionStatus) => {
  const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: 400,
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
  }));

  const StyledTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  }));

  return (
    <StyledCard>
      <CardContent>
        <StyledTypography align="center">Subscription</StyledTypography>
        <Box mb={2}>
          <Typography variant="body1">
            <strong>Status:</strong> {status}
          </Typography>
        </Box>
        {currentPeriodStart && (
          <Box mb={2}>
            <Typography variant="body1">
              <strong>Start:</strong> {new Date(currentPeriodStart).toLocaleDateString()}
            </Typography>
          </Box>
        )}
        {currentPeriodEnd && (
          <Box mb={2}>
            <Typography variant="body1">
              <strong>Renewal:</strong> {new Date(currentPeriodEnd).toLocaleDateString()}
            </Typography>
          </Box>
        )}
        <Box mt={4} textAlign="center">
          <Link href="mailto:info@thesynthesis.app" color="secondary">
            Contact Us
          </Link>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export { SubscriptionStatus };
