export interface IAuthToken {
  // snake_case as specified in OAuth2 specifications
  access_token: string;
  refresh_token: string;
  token_type: string;
}

export interface IProfile {
  userId: string;
  bio: string;
  image: string;
}

export interface IUser {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  scope: string[];
  picture?: string;
}

export interface IMoneyAmount {
  amount: number;
  currency: string;
  divisor: number;
}

export interface IUserLocal {
  pk: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

// --------------------
// Books related - to be moved to separate file
// --------------------

export interface IBook {
  id: number;
  name: string;
  slug: string;
  author: string;
  user_created: IUser;
  summary: string;
  mindmap: number;
  likes: number[];
  categories: string[];
  actions_count: number;
  image_url: string;
  created_at: string;
  updated_at: string;
}

export interface IBooksPaginated {
  data: {
    count: number;
    next: string;
    previous: string;
    results: IBook[];
  };
}

export interface IBookChapter {
  id: number;
  name: string;
  order: number;
  book: number;
}

export interface IBookData {
  id: number;
  book_name: string;
  data_str: string;
  book: number;
}

export interface ISearchResult {
  ID: string;
  Author: string;
  Title: string;
  Year: string;
  query: string;
}

export interface IInitiateBookTaskResponse {
  taskId: string;
  startDate: string;
}
export interface IInitiateYouTubeTaskResponse {
  taskId: string;
  startDate: string;
}

export interface ITaskStatusResponse {
  status: string;
  summary?: string;
}

export interface ISectionSummary {
  section_title: string;
  section_summary: string;
}

export interface IChapterSummary {
  chapter_key: string;
  chapter_summary: string;
  sections_summaries: ISectionSummary[];
}

export interface ITranscriptSummary {
  transcript: string;
  summary: string;
}

export interface IMultiTranscriptsSummary {
  sections_summaries: string;
  summary_of_summaries: string;
}

// --------------------
// YouTube related - to be moved to separate file
// --------------------
export interface ISemanticSearchMetadata {
  id: string | null;
  channelId: string;
  channelTitle: string;
  startSecond: number;
  endSecond: number;
  numSegments: number;
  publishedAt: string;
  text: string;
  thumbnail: string;
  title: string;
  totalDurationSeconds: number;
  url: string;
  videoId: string;
}

export interface ISemanticSearchResult {
  id: string;
  score: number;
  metadata: ISemanticSearchMetadata;
}

export interface ISemanticSearchGroupedResponse {
  queryId: string | null;
  results: ISemanticSearchGroupedResult[];
}

export interface ISemanticSearchGroupedResult {
  videoId: string;
  meanScore: number;
  matchedSegments: ISemanticSearchResult[];
}

export interface IYouTubeChannel {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  category?: string;
  handle?: string;
}

export interface IYouTubeVideo {
  id: string;
  channelId: string;
  title: string;
  description: string;
  thumbnail: string;
  publishedAt: string;
}

export enum MediaTypeEnum {
  youtube = "youtube",
  podcast = "podcast",
  lecture = "lecture",
}

export interface ISegmentLike {
  segmentId: string;
  userId: string;
  queryHistoryId: string;
  channelId: string;
  segmentMetadata: ISemanticSearchMetadata;
  mediaTypeSlug: string;
}
