const baseURL = process.env.REACT_APP_BASE_URL || "http://127.0.0.1:8000/v1";

const BookSummariserBaseURL = "https://t3u0ska25m.execute-api.us-west-2.amazonaws.com/";
const ChromeExtensionTaskBaseURL = "https://iqdjo4e56k.execute-api.us-west-2.amazonaws.com/";
const PublicAssetsBaseURL = "https://d3nq8963cg3tjx.cloudfront.net/";

export const NotionRedirectUrl = process.env.REACT_APP_NOTION_REDIRECT_URL || "";
export const NotionOAuthClientId = process.env.REACT_APP_NOTION_OAUTH_CLIENT_ID || "";

export const ChromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID || "";

export const ENDPOINTS = {
  login: `${baseURL}/user/token`,
  refreshToken: `${baseURL}/user/refresh-token`,
  googleLogin: `${baseURL}/user/google-token`,
  register: `${baseURL}/user/register`,
  me: `${baseURL}/user/me`,
  balance: `${baseURL}/balance/`,
  notionLogin: `${baseURL}/notion/login`,
  notionDatabases: `${baseURL}/notion/database/all`,
  notionProcessDatabases: `${baseURL}/notion/database/process`,
  notionDBs: `${baseURL}/notion/db`,
  queryHistory: `${baseURL}/query-history/all`,
  queryHistoryWithLikes: `${baseURL}/query-history/all-with-likes`,
  semanticSearchYouTube: `${baseURL}/youtube/query`,
  // TODO: remove monkey patch
  semanticSearchYouTubeGeneral: `${baseURL}/youtube/query-general`,
  semanticSearchYouTubeResources: `${baseURL}/youtube/resources`,
  processChannelYouTube: `${baseURL}/youtube/process-channel`,
  processChannelCostYouTube: `${baseURL}/youtube/process-channel-cost`,
  processHistoryYouTube: `${baseURL}/youtube/process-history`,
  channelInfoYouTube: `${baseURL}/youtube/channel-info`,
  semanticSearchPodcast: `${baseURL}/podcast/query`,
  likeSegment: `${baseURL}/likes/segment`,
  likedSegmentsAll: `${baseURL}/likes/segment/all`,
  searchBook: `${BookSummariserBaseURL}search-book`,
  initiateBookTask: `${BookSummariserBaseURL}book-task`,
  summariseTaskStatus: `${BookSummariserBaseURL}task-status`,
  searchYouTube: `${BookSummariserBaseURL}search-youtube-transcript`,
  summariseYouTubeTaskStatus: `${BookSummariserBaseURL}transcript-task-status`,
  summariseMultiYouTubeTranscript: `${BookSummariserBaseURL}multi-youtube-summaries`,
  chromeExtensionTask: `${ChromeExtensionTaskBaseURL}task`,
  publicAssetsBaseURL: PublicAssetsBaseURL,
  availableYouTubeChannels: `${PublicAssetsBaseURL}metadata/youtube_channels.json`,
  stripeCheckout: `${baseURL}/stripe/create-checkout-session`,
  stripeSubscriptionStatus: `${baseURL}/stripe/subscription-status`,
  chatSessions: `${baseURL}/chat/sessions`,
  chat: `${baseURL}/chat/`,
};
