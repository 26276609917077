export const CHANNELS_INFO: Record<string, Record<string, string>> = {
  "@thediaryofaceo": {
    id: "UCGq-a57w-aPwyi3pW7XLiHw",
    title: "The Diary Of A CEO",
    thumbnail:
      "https://yt3.ggpht.com/xSwK9eN4KTkDzIA0Qfv6ng_QQ1JrI_GxGFRp_97TWPaO5euI6Z1gxjNUksfktPka6b26l_zx=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@hubermanlab": {
    id: "UC2D2CMWXMOVWx7giW1n3LIg",
    title: "Andrew Huberman",
    thumbnail:
      "https://yt3.ggpht.com/5ONImZvpa9_hYK12Xek2E2JLzRc732DWsZMX2F-AZ1cTutTQLBuAmcEtFwrCgypqJncl5HrV2w=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@deepdivewithaliabdaal": {
    id: "UChfo46ZNOV-vtehDc25A1Ug",
    title: "Deep Dive with Ali Abdaal ",
    thumbnail:
      "https://yt3.ggpht.com/IznGH-Vc3Av9RDp2brSlwLhVX8m24MtMczgtcvtrC9tTRJw5sArFZQFjsleyPw57ZO0RZvk8Kg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@lewishowes": {
    id: "UCKsP3v2JeT2hWI_HzkxWiMA",
    title: "Lewis Howes",
    thumbnail:
      "https://yt3.ggpht.com/OzZntr8xhtrnIczS09IJPlPbdU7smVn4okfVWDDrevOz5WtH3o2MrJioZ5AOf_AaGJagqw7y4w=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@tkppodcast": {
    id: "UCLtTf_uKt0Itd0NG7txrwXA",
    title: "The Knowledge Project Podcast",
    thumbnail:
      "https://yt3.ggpht.com/DAXRofO0ZovlZt63VXCs29vbnLqjAe3kDYyi9GXAIJ9ez4a_hi40_JamjwwmEICbOWJ6jFq_-g=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@timferriss": {
    id: "UCznv7Vf9nBdJYvBagFdAHWw",
    title: "Tim Ferriss",
    thumbnail:
      "https://yt3.ggpht.com/OrRUxv5-jCFQMrIpDDY5Q5ugQzM4VwEGYBzbh_tik2TUUN5ViyuT-8rUjV7X-cNhFcjnv6vhaQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@jayshettypodcast.": {
    id: "UCbk_QsfaFZG6PdQeCvaYXJQ",
    title: "Jay Shetty Podcast",
    thumbnail:
      "https://yt3.ggpht.com/_cbhMUCXyXtacESy1DDO-psAYqM7YtAK5LUdOO8un73tzyvM0Ac9PJbUmc02CDwygdcpZXiH=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@mogawdatofficial": {
    id: "UCilMYYyoot7vhLn4Tinzmmg",
    title: "Mo Gawdat",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQLqpysDWQZlkEOqwhC_vn9ma0Hgz_FAsLChe0H2w=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@jordanharbingershow": {
    id: "UCGLcx_fFZ3GlLXO5anHSm9w",
    title: "The Jordan Harbinger Show",
    thumbnail:
      "https://yt3.ggpht.com/XQZ00DH3EnWpb6j8gNxIzJbIdSwVa7vW508VwfATJJd8nUgqeP-uYt-Icjm5mO-p9FUp3bDP=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@chriswillx": {
    id: "UCIaH-gZIVC432YRjNVvnyCA",
    title: "Chris Williamson",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRVB6Al3iJ-1e-DGYFwKXncLRh3o92psXPEgMnpGg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@highperformancepodcast": {
    id: "UCT4hFq01krOatjiSG7z3iag",
    title: "High Performance",
    thumbnail:
      "https://yt3.ggpht.com/TWTo9cQ9x0UG6dn9a6z-kLpPJipflO570ZiJKDx4uuzYMkZZADe_RX-sBeszbQ0EPeM6D7slIwU=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@tombilyeu": {
    id: "UCnYMOamNKLGVlJgRUbamveA",
    title: "Tom Bilyeu",
    thumbnail:
      "https://yt3.ggpht.com/YlvzX-ktdEvgvN6TPOvjZWldQ0bCA1F9TPwVWTqOKUXZIDk5XHpQP5JE_tUmkF2k9UJZU_aR=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@allin": {
    id: "UCESLZhusAkFfsNsApnjF_Cg",
    title: "All-In Podcast",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQR2pf7CrYSNIJ7yJ0f4PQspP8Q9NKOUvTPPvuh=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@ramitsethi": {
    id: "UC7ZddA__ewP3AtDefjl_tWg",
    title: "I Will Teach You To Be Rich",
    thumbnail:
      "https://yt3.ggpht.com/4tXlSb8X8-bXEUZw68Zqt0pgC7D7Mnr__2k5WWZ6tdZuK109q3nTpZQeUVXKe4KC1v5S5Z7y6sU=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@drlauriesantos": {
    id: "UCFfUSTVKFCfXl6PVyG08zxg",
    title: "Dr. Laurie Santos",
    thumbnail:
      "https://yt3.ggpht.com/fWA3KP4kvbjbQxTSKW8zF906H-mjSxp-lSroCfhPvbsDoRjf3nCbXmnJ32BGO7D44o9ASIur=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@richroll": {
    id: "UCpjlh0e319ksmoOD7bQFSiw",
    title: "Rich Roll",
    thumbnail:
      "https://yt3.ggpht.com/UXI7ZyoQPoHhboBBUD8pscuva2dd-hlTxMjRDHVg96eoHOfiru5CFoWvkVe8pFiyvlB33_ceow=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@jackrhysider": {
    id: "UCMIqrmh2lMdzhlCPK5ahsAg",
    title: "Jack Rhysider",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT70kudl-_ElLVJCEIc3lkKKSHY3Mde8HVxAmmdPQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@lexfridman": {
    id: "UCSHZKyawb77ixDdsGog4iWA",
    title: "Lex Fridman",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRPXFF1l1EPjxogHsozet1vHM_hPaiqWCkKI8cJCG0=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@bigthink": {
    id: "UCvQECJukTDE2i6aCoMnS-Vg",
    title: "Big Think",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQZUmKfsc6_z4Ph-cwNJVbafNAdEqRZ3y0kkVdoCQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@codiesanchezct": {
    id: "UC5fI3kxC-ewZ6ZXEYgznM7g",
    title: "Codie Sanchez",
    thumbnail:
      "https://yt3.ggpht.com/oD4pxFAjfVIeA0gXVDNnWWq6jsa7MRd2aOfk0vPxbuFwwL8X6jsbVjwKQ4aLHwWEc8E5ZH0crg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@noahkagan": {
    id: "UCF2v8v8te3_u4xhIQ8tGy1g",
    title: "Noah Kagan",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQEVrXbheezPa2SepKCNlMmOVDtLCU503YZUoNgJg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@patflynn": {
    id: "UCGk1LitxAZVnqQn0_nt5qxw",
    title: "Pat Flynn",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT4jAWgKttmjnFGdRAebVgSPjntUobLisStW2XFfA=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@tonyrobbinslive": {
    id: "UCJLMboBYME_CLEfwsduI0wQ",
    title: "Tony Robbins",
    thumbnail:
      "https://yt3.ggpht.com/eYfvpz_EGKcpNwYlkPBhI22dL4Eia4e91FvpJYOPuclf9EtwLQCy81LFsI6448PhV7SdA6frIg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@alexhormozi": {
    id: "UCUyDOdBWhC1MCxEjC46d-zw",
    title: "Alex Hormozi",
    thumbnail:
      "https://yt3.ggpht.com/7spVLsUWY48DEyACdgIMt8C_4v1QnAkhFtw40pl9c_ieUrQA-Q4gCw70Y2fvhyY707WX25WTyIo=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@leilahormozi": {
    id: "UCxCcu9pet4dljrBLf8R5nwA",
    title: "Leila Hormozi",
    thumbnail:
      "https://yt3.ggpht.com/o28H_0Y3-NmjfWjYIWapECdR9EJnljPHAaVDSp4iXkxoDojmYhdk-r8H_CCNXUkx4-poDWhs0n4=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@grahamstephan": {
    id: "UCV6KDgJskWaEckne5aPA0aQ",
    title: "Graham Stephan",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT9feBqh2MOFIKF8WmhCekO3iEZ-vmxW57Zsi5fWQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@andreijikh": {
    id: "UCGy7SkBjcIAgTiwkXEtPnYg",
    title: "Andrei Jikh",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQp3_sjOxdfKG4ZI3hn5svhywhpH0rNL8WanW-www=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@nateobrien": {
    id: "UCO3tlaeZ6Z0ZN5frMZI3-uQ",
    title: "Nate O'Brien",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSCW7_yDosaUn12oV6-iu4yOvKdFjfWMPRmpnblew=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@whiteboardfinance": {
    id: "UCL_v4tC26PvOFytV1_eEVSg",
    title: "Marko - WhiteBoard Finance",
    thumbnail:
      "https://yt3.ggpht.com/Y8by0sL-REIfqICbvwrHp2DFUSa_-fpyBPpZQ9ofUjtfPlU0qngZHHTc8ribYXazbV68qcbD=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@ryanscribner": {
    id: "UC3mjMoJuFnjYRBLon_6njbQ",
    title: "Ryan Scribner",
    thumbnail:
      "https://yt3.ggpht.com/4EfEWgrHhcfp3RcmMP7sHt_nQ2AFG0yHPxwoxkH1Hni_ADpmB_kuNwS1ppbKpi96YKVWSb9fgBg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@financialeducation": {
    id: "UCnMn36GT_H0X-w5_ckLtlgQ",
    title: "Financial Education",
    thumbnail:
      "https://yt3.ggpht.com/2yG_-sY0k83cbVYBLHjkD7GFr1H_VynjBrD_ZFkHCQfiIX08e1ztPmd7_6ulbQG0EIk_YTmp9g=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@thefinancialdiet": {
    id: "UCSPYNpQ2fHv9HJ-q6MIMaPw",
    title: "The Financial Diet",
    thumbnail:
      "https://yt3.ggpht.com/x37p_UUXIPFLH4emPO1GqoJafXnQM8Yi02s7ioVXdhtBlhkYcuwIoIO85JHHX_lsTAB0qANo=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@mappedoutmoney": {
    id: "UCfRqvJFJ6LLQ62lKmOUXATw",
    title: "Nick True - MappedOutMoney",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZS_g8rLr_fgp6VrxEZptNmMe0VU8I3HFb2psCieVw=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@debtfreemillennials": {
    id: "UCvs2mwDS-ZiIeJ01kvzarbQ",
    title: "Debt Free Millennials",
    thumbnail:
      "https://yt3.ggpht.com/JcMRDpK8WectYYtev-2zs39k2ed5SQuVyE_DeE_VyGJjkFUewcUYm3ZWhw9UzowBIGNPgPDHUw=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@grantcardone": {
    id: "UCdlNK1xcy-Sn8liq7feNxWw",
    title: "Grant Cardone",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSBc7llzrOPeWNCzfOJxF30AIpvmCXOQ85-Cwc4sA=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@biggerpockets": {
    id: "UCVWDbXqQ8cupuVpotWNt2eg",
    title: "BiggerPockets",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZTN5kpSj2ij6uB4z1UfZN5mETApLORDh25dnZ0pIQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@jungernaut": {
    id: "UCQglaVhGOBI0BR5S6IJnQPg",
    title: "Brian Jung",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQMoxlX_RFuPRppYADlHi3a8gNB2UQq6UkYPNwPIQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@wealthhacker": {
    id: "UCkNgKCu9062P0CPyVoBI5sQ",
    title: "Wealth Hacker - Jeff Rose",
    thumbnail:
      "https://yt3.ggpht.com/Cdumn-GI8LMREbtf9_TJbTOVtBiKLVMtmGR6I7R4W0AO4QMT8ulmLAQs58YHlKriR9f5H5vW=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@hisandhermoney": {
    id: "UCCnXqVJZq_cD9wpycpml9LQ",
    title: "His And Her Money",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRYN0mvjIQpMqGs20iKB8cIEd7qmDSYDEzzZDcyDQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@coinbureau": {
    id: "UCqK_GSMbpiV8spgD3ZGloSw",
    title: "Coin Bureau",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQVF3G0o4MwawbBKIyvHb0zOqMgi2M6B_WOT9Oh=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@meetkevin": {
    id: "UCUvvj5lwue7PspotMDjk5UA",
    title: "Meet Kevin",
    thumbnail:
      "https://yt3.ggpht.com/qkQT5Fv5lnqW_c15f9IAsSWiIDY6DFJstZ84qhVI70hUUAXiJPkVWE_tHBDG2gwUvgRQN9VNPw=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@jordanpagefuncheaporfree": {
    id: "UCIBM8DAHoehmJ7_LSLDkB3A",
    title: "Jordan Page, FunCheapOrFree",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSR9f-sBL5IvYrMZd1Fv0HagWzaAp-j2rzBwIfnNQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@theramseyshow": {
    id: "UC7eBNeDW1GQf2NJQ6G6gAxw",
    title: "The Ramsey Show Highlights",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZS7phaXZUTHvVz-PoZb8aM1Lb4HNcV22cYgk3rw4A=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@minoritymindset": {
    id: "UCT3EznhW_CNFcfOlyDNTLLw",
    title: "Minority Mindset",
    thumbnail:
      "https://yt3.ggpht.com/5tHK-GQDOd0BiFdi1W_a8NF-hk5g565v5XqWltgnY5Myjv-MAMh04BP1iViouK0K1TWyp6SKsA=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@theplainbagel": {
    id: "UCFCEuCsyWP0YkP3CZ3Mr01Q",
    title: "The Plain Bagel",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSiEd_OxLQjZe-CG64Q6DRanbUL5cOedlIJvKLqyg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@becleverwithyourcash": {
    id: "UC_T7G3RjQe7ghD0ikr4reuw",
    title: "Be Clever With Your Cash",
    thumbnail:
      "https://yt3.ggpht.com/pxLqqWYfjK6oW1M-xRNw4uaQXzKeDtwN7itJTtEcuzJM_9MdbrvNm8cUae2Qj0v-x6GkzdPD=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@johnsfinancetips": {
    id: "UCLB4e-4vO2ioj7350YeE6-g",
    title: "John Liang",
    thumbnail:
      "https://yt3.ggpht.com/M07Tt5xfTE2J__j-Rz6scTUeUNWsotuG2expy0viMTE4QDApwuyjgLLoBqRjMThms_dvxJRH=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@nischa": {
    id: "UCQpPo9BNwezg54N9hMFQp6Q",
    title: "Nischa",
    thumbnail:
      "https://yt3.ggpht.com/zkno7QTbnaWk62_ntJbnWRQ89DCy4glrtjNWYhOmQ9SQkaw2YgyKgr4vVF6uOo8_enTtDxhb=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@theschooloflifetv": {
    id: "UC7IcJI8PUf5Z3zKxnZvTBog",
    title: "The School of Life",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZTMYFPPhORFgtu2YXYB50JzmCR7Z1Ab8VJlhEvWgQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@melrobbins": {
    id: "UCk2U-Oqn7RXf-ydPqfSxG5g",
    title: "Mel Robbins",
    thumbnail:
      "https://yt3.ggpht.com/0PuR0CxjvPqBvingy5jCIIAoyTFCD48ZvI2XJa-UMlz7yhiKzfUEesmGabAM0X3BeSm6uZazOlg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@evancarmichael": {
    id: "UCKmkpoEqg1sOMGEiIysP8Tw",
    title: "Evan Carmichael",
    thumbnail:
      "https://yt3.ggpht.com/9TFeX9sxYILjmwkYZj7so4lR2BKDYuRZJlxJjrc7NJKjTOIsEbAmYVOboTo0DEVi_eJOqrW66cs=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@thomasfrank": {
    id: "UCG-KntY7aVnIGXYEBQvmBAQ",
    title: "Thomas Frank",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQ7RjXn1-vCKywyqbQ_bga9CxBoNUxcvfSUVneGuSg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@mattdavella": {
    id: "UCJ24N4O0bP7LGLBDvye7oCA",
    title: "Matt D'Avella",
    thumbnail:
      "https://yt3.ggpht.com/ZWxpvdMOumZ2xHDnomL_asj7Zgt_4iOO7b37KIXvW-R462d0HoVSuqbttjdyYBWrHhwOVKVG_Rk=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@dr.benjaminhardy": {
    id: "UC07WXGmXVbNrv3VMOp5DvDw",
    title: "Dr. Benjamin Hardy",
    thumbnail:
      "https://yt3.ggpht.com/YBkNWiuJOsKIhRaiNvefbJiS-wd-08OSC3MfLHLNSOfWxABDJg-oURQY8ZVb_oxw1pC3k7jBEg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@brendonburchard": {
    id: "UCySH3WVP-5d4aJIfn8-WoPA",
    title: "Brendon Burchard",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSviA5u4hSxpet2v6XE7VTeZajYtOTLCn-La_ln=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@amytv": {
    id: "UCR9gMSj0UUxGvgfpNhhF3Jw",
    title: "Amy Landino",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT-CFs2VEKaPf5rJ_MNMzOb2Bovb-t_YZwzuLzFjg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@muchelleb": {
    id: "UC0sA4gvlWFHfdwaPlBDqnGg",
    title: "muchelleb",
    thumbnail:
      "https://yt3.ggpht.com/UJkYAwuAVcMSCRfcI6VTzMLXlL-lll9cgQd0rNJtnZNfmbSp1UqJkFJAczxiOuvTNsDzN_H8PA=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@actualizedorg": {
    id: "UCgeicB5AuF3MyyUto0-M5Lw",
    title: "Actualized.org",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRTPv5Kj6aq4nAPS-C-JhjcmiU9NIORdP5pLt-5JQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@garyvee": {
    id: "UCctXZhXmG-kf3tlIXgVZUlw",
    title: "GaryVee",
    thumbnail:
      "https://yt3.ggpht.com/P6k8sraHd-wcO3HMXy_LYa4tWpavAP9v9Gh_AcD6IAFxkbk6akynEzi03EFkNrgVTaMsPV9Z=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@goalcast": {
    id: "UCc4IYtPKkJLSAHHuJx1GiGQ",
    title: "Goalcast",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSQlKGv3Ow2DSB30xWdGOpxmQ3kPBFgduxWIDI8SA=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@beinspiredchannel": {
    id: "UCaKZDEMDdQc8t6GzFj1_TDw",
    title: "Be Inspired",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZTwQb7IoC2QYar9hi69aTdxYo8bv80YQa9GMswjPQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@principlesbyraydalio": {
    id: "UCqvaXJ1K3HheTPNjH-KpwXQ",
    title: "Principles by Ray Dalio",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT-XXgWQHBozu9gxDiFqEXrfHOXiqxfNid59iEbrQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@edmylettshow": {
    id: "UCIprGZAdzn3ZqgLmDuibYcw",
    title: "Ed Mylett",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZR4qeaG_-b852oXagdkLQCjEXqknAyntKEWy6KPZA=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@improvementpill": {
    id: "UCBIt1VN5j37PVM8LLSuTTlw",
    title: "Improvement Pill",
    thumbnail:
      "https://yt3.ggpht.com/7XbNs1TDdQbTJYp3eVPgPnx9u-G05mBcJVx2j8mOqF0m6E2oQ36nLyH0SXvqughCEzZqjWpJ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@theartofimprovement": {
    id: "UCtYzVCmNxrshH4_bPO_-Y-A",
    title: "The Art of Improvement",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT96kS07kB_2WGZuZzN1Hx-tdMUiRwyDbdAdk2K=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@charismaoncommand": {
    id: "UCU_W0oE_ock8bWKjALiGs8Q",
    title: "Charisma on Command",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZToAZIgeIFNkBX7MDb2PRqBlfreLoak6KTDm3AvRw=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@betterideas": {
    id: "UCtUId5WFnN82GdDy7DgaQ7w",
    title: "Better Ideas",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRs41KjX5kARk8ZSRwtTJ2oWmWrovlISMU4PADn=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@betterthanyesterday": {
    id: "UCpExuV8qJMfCaSQNL1YG6bQ",
    title: "Better Than Yesterday",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZTs34wm2roY5DqMxyHtfYu_E9Y_XJwReoNfUx6dAQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@jeffsu": {
    id: "UCwAnu01qlnVg1Ai2AbtTMaA",
    title: "Jeff Su",
    thumbnail:
      "https://yt3.ggpht.com/FGRGJWhnadrqjQMrNbgjff3re4aVyHcOxCfc1_tYZvfd2SCWFrS11mkUR-LyD28P1bqZICTq=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@theinfographicsshow": {
    id: "UCfdNM3NAhaBOXCafH7krzrA",
    title: "The Infographics Show",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRfHgdwyoprqqphkAoyQVGjKWysTKX8oreH3qCiVw=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@psych2go": {
    id: "UCkJEpR7JmS36tajD34Gp4VA",
    title: "Psych2Go",
    thumbnail:
      "https://yt3.ggpht.com/eMTgxSTWjsMjfL-VvP0mbkGQ8XV0cZ5AY6Kj-3zYCfdkDn_yYSzvwXXPJoWDEAPlLUBzgCE1qQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@dailystoic": {
    id: "UCkUaT0T03TJvafYkfATM2Ag",
    title: "Daily Stoic",
    thumbnail:
      "https://yt3.ggpht.com/RjImzyCYScR7M_P5H1OAmGhugkXbhjku92eMrjjqSWwoDwhS4Ds8bgWnMnsxAxFCMBCQQVSC=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@toolfinderhq": {
    id: "UCYyaQsm2HyneP9CsIOdihBw",
    title: "Tool Finder",
    thumbnail:
      "https://yt3.ggpht.com/iigGGGpQwNf5-wctYYv8P19C7VUww7KurpJzKbpWjXZytdb0tfL_cRDwc_RTkXRXWY9jxgGwBg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@thematthewhussey": {
    id: "UC9HGzFGt7BLmWDqooUbWGBg",
    title: "Matthew Hussey",
    thumbnail:
      "https://yt3.ggpht.com/AWc8JHsJDpF0FaP8ylNgMwE_1z1kl65YfQ5_n51ZyUzmii742bPMu78SQzoVVn9KOwRdjOb7sSA=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@theholisticpsychologist": {
    id: "UCtEWTaMjqOH8J1Gy06Ey0Yg",
    title: "The Holistic Psychologist",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSr9d_Ba_UxPNF9EDY1jJXZsuJoE-Bim9zEF-pDCw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@reallifelore": {
    id: "UCP5tjEmvPItGyLhmjdwP7Ww",
    title: "RealLifeLore",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSr08rlTfI3Zq5lYPK_292TJEMdpgYgQg0Ql0kW3Q=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@joinZOE": {
    id: "UCa09am-cOsC-FSgr_nLkFFA",
    title: "ZOE Science & Nutrition",
    thumbnail:
      "https://yt3.ggpht.com/7lIoH3cNEYjoUV9WwR0udH67Ov6yW5zikbGOjYtysJtJupBuZUcbzt0FP-c2vHl865G0rZQZ-Q=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@PursuitOfWellnessPodcast": {
    id: "UCAUN0lbKJFUVzrys9qtgbdw",
    title: "Pursuit of Wellness",
    thumbnail:
      "https://yt3.ggpht.com/fSOAl8B6RQGDrb7UGFtbVTpa5XBGfFB413rDrOBhCjcHpioTU9hObpUrsvWSFSR7pDsboVjzcA=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@RealAlexClark": {
    id: "UCcbPQtclBJp_8se9a2t8AoA",
    title: "Real Alex Clark",
    thumbnail:
      "https://yt3.ggpht.com/AcltbREaqffoIiV7t2eRCMhMage8Deop_nzgS-tNjWFsCzLnc-Y04vEl3LJ-ziDUN-9lqbjKGw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@TheDrJohnDelonyShow": {
    id: "UC4HiMKM8WLcNbt9ae_XNRNQ",
    title: "The Dr. John Delony Show",
    thumbnail:
      "https://yt3.ggpht.com/hkYW0UyoVghPCEBq4O4nR_cE4kA6AH6QgeGS4uDNaOkSE0EOVVF6bDXpag41jFl40-dCxGhkww=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@DoctorMike": {
    id: "UC0QHWhjbe5fGJEPz3sVb6nw",
    title: "Doctor Mike",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_k0pos95d5Yr1i6FX_KCYvrueDqhAkbtURk5PGdFYHYgjU=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@MayimBialik": {
    id: "UCTOocPnDh2YQZZwh86K2OxA",
    title: "Dr. Mayim Bialik",
    thumbnail:
      "https://yt3.ggpht.com/mO4PDCs6I5hanPFH-UeUi9ejQL0nnou6Dlkg3AvwrDzyDkk5B08SmBKln5MICIo3RK81zLbD=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@JohnRMiles": {
    id: "UCTLdXATpxf8LP3riC0_mkKw",
    title: "John R. Miles",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_lPeYsP2uAwgHaRNW55JtwCTVuflxrzhtxTZA-pvYFTewk=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@drjoshaxe": {
    id: "UCgtp61tf9tYF7nG_gIQ94LQ",
    title: "Dr. Josh Axe",
    thumbnail:
      "https://yt3.ggpht.com/1cQGAmqQ3UAySQdfaeab0d4Yh7UHSha5aCYCH_DWpKZVv-z6X3STrXwM-0zJzkkkg7NpVJVTaw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@PeterAttiaMD": {
    id: "UC8kGsMa0LygSX9nkBcBH1Sg",
    title: "Peter Attia MD",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_l5iWeGucXjd7rqpIH65NHoCEL1c2AYL9l9IuFZzjDd3Q=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@DrChatterjeeRangan": {
    id: "UCDnwlb3IQDPJtFysPUJbDFQ",
    title: "Dr Rangan Chatterjee",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_mMkAnbnYEXB8UicOd5y8K8lNENQmYWSTlV3czjgYCeYpI=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@MindPumpShow": {
    id: "UCq0hKkwnW5Cw1wQqu455WrA",
    title: "Mind Pump Show",
    thumbnail:
      "https://yt3.ggpht.com/YTVb9QsVduxEdkmkhax7BYVRoG_hsUcDD_c2jZ_B5Y1XavLoNSkgOPFBkQmk0JLFfZxmtpGF=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@fukkenfeelings": {
    id: "UCbucB_lN94O5WI0oSuUQIQQ",
    title: "These Fukken Feelings Podcast\\u00a9",
    thumbnail:
      "https://yt3.ggpht.com/o0PlvBBFouQ7FTd3_YvjMuXljk1aEmsB6BykXMiRU4TaSLqL0sQV_qNvAkLuU57fgo9NOIfaPA=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@drmarkhyman": {
    id: "UC5IuDMmKWSsBFB0iKky6aEQ",
    title: "Mark Hyman, MD",
    thumbnail:
      "https://yt3.ggpht.com/CnZHo4oM2xApBWZuJ2a3HFxZh-OJnxCixUFcskMezomMwVIA3rTJp3-NeE_uWQoQoUekkrdcd5I=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@mindsetmentorpodcast": {
    id: "UCHl3aFKS0bY0d8JwqNysaeA",
    title: "The Mindset Mentor Podcast",
    thumbnail:
      "https://yt3.ggpht.com/bz73HmgQ144ke89zFpCWFmoTqRX9ZzQwVxG0VsQotHS3SdWbfXoAB8gk2_qjeBn0ErW3_E-9=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@ultimatehumanpodcast": {
    id: "UCLHKyx7IhZ_esCs4TSWAjEg",
    title: "Ultimate Human Podcast with Gary Brecka",
    thumbnail:
      "https://yt3.ggpht.com/_6xYf3gHcdQxNllJG05-BKrpzluPjkXBs5JZQ9GZhJvuIJTXsw8o06NqDA_V7nAzeWUXkWAX=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@OfficialProverbs31Ministries": {
    id: "UCPWdfu35gM7lPbRYDG_iagg",
    title: "Proverbs 31 Ministries with Lysa TerKeurst",
    thumbnail:
      "https://yt3.ggpht.com/xRJcwzwO9fnRbst-0GzIg4EU3IO4wV0KRf-Lq6pRw1KkavdtRnCRsuxzW2imw8LGXc5KsfNdnw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@DaveAspreyBPR": {
    id: "UC1KoulyXR8gBGyxv7r6pWQg",
    title: "Dave Asprey",
    thumbnail:
      "https://yt3.ggpht.com/TvECLYAsAmPYxI9LKRrF-SpvVMKBUVNHsv3NoJn7XbfIx9vWciFrqmhM7mxcezuW_FGTj8bSvg=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@DrGabrielleLyon": {
    id: "UCWPQJeWz4pvccA3lIoZ7j1Q",
    title: "Dr. Gabrielle Lyon",
    thumbnail:
      "https://yt3.ggpht.com/y29nRSG_4yagG1wtQKLq3WLt1xYeA7MGoWr-Fx0zRT9oE-KLCvo8ydX38hfBb9O64fPCGMJ9=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@maintenancephase880": {
    id: "UCEkjcu4jzQsU6UWDOe8W8kA",
    title: "Maintenance Phase",
    thumbnail:
      "https://yt3.ggpht.com/R1En-D3quTzrP0nOCgkAYEBC8aaIOoA70YYHeRq99zS2o5m8_JrDjaHrYqqwlo9k-fMfECwXEw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@thegottmaninstitute": {
    id: "UCnT1R08f7FHLab3nB4f0AGQ",
    title: "The Gottman Institute",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRu7XO6uCWGLCp0FmIDhvjzTulw03NGw2PU6iZF=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@HealthHacksPod": {
    id: "UCuuzSsSKKqlqS4V8pqqdjEg",
    title: "Health Hacks with Mark Hyman, MD",
    thumbnail:
      "https://yt3.ggpht.com/e2VLg8-WHNHHgOk6k30a-IDVqjYL6SJCJh2RTwayOHyuG8loY0xX_T2BMG092AYvTo5onrhs=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@nathalieniddam9630": {
    id: "UCmholC48MqRC50UffIZOMOQ",
    title: "Nathalie Niddam",
    thumbnail:
      "https://yt3.ggpht.com/4YBT1g76zYQJBb-w-Z2c6Hw94DN9c2EZgDQ3Nc7kptcKma2I3rJBFz1uh152ZCjQyHj754YQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@DrDavidJockers": {
    id: "UCIAZtlZSFlnBp_hAlvB6QSw",
    title: "Dr David Jockers",
    thumbnail:
      "https://yt3.ggpht.com/OLoipmdfFL76-fKfd3dVfTyt_LeDZy6tiR67qvBzDf_H6JU-egP8W1Qg3lcJknLwILhbfLsyKw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@FoundMyFitness": {
    id: "UCWF8SqJVNlx-ctXbLswcTcA",
    title: "FoundMyFitness",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_lughkALlWqkUCxPEWI0iAn92aFZIf7GS4g2AdPz6EwmHs=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@thegirlfrienddoctor": {
    id: "UCmWLoqXyvtSyylB7bjCipnA",
    title: "Dr. Anna Cabeca The Girlfriend Doctor",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_kqqvzRqrXPAriAHlSXoNTKco6j3P0b_I5Q1H6weadI_Q=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@drandygalpin": {
    id: "UCe3R2e3zYxWwIhMKV36Qhkw",
    title: "Andy Galpin",
    thumbnail:
      "https://yt3.ggpht.com/-ICtmz7OtNQgKsq7ETxhtNM9lh-2dYazQCLOurln8PVaDLdfxsOWjUOB2bK-yXvPQjr5VB3jWw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@lizmoodypodcast": {
    id: "UCwQVy16LQhZbgO4losH8W_Q",
    title: "Liz Moody Podcast",
    thumbnail:
      "https://yt3.ggpht.com/tTiKlh--SlTeQqtD9e98Jo69Ni3j_GRVLG8KUnR9db3rioaEID4d9y25d8WYPYBVqGsDgpxkXw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@BalancedBlackGirl": {
    id: "UCBxaP1FieK4i9i_mfh0PdWg",
    title: "Balanced Black Girl",
    thumbnail:
      "https://yt3.ggpht.com/yZVpzswE9XPvdFeuaKpzkHxh9EZJQG3pSJWwQo6GTz_ZbqAxrTxAZhVnh2ZDHPZTnXMSQ5_h8A=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@kellycaspersonmd": {
    id: "UCalNuuq2IHBHeK3bJ3gyUYQ",
    title: "You Are Not Broken - Dr. Kelly Casperson",
    thumbnail:
      "https://yt3.ggpht.com/shJx8cyH5v-CjpNEKclc5i5viNeMAqzCWt0KYWdWxI7Q9mBFKwQq8azxEiTP2LIaTs-M2qlevQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@NeimoyaBasden": {
    id: "UCrhSRUfxm1jmALtH5CdlGag",
    title: "Neimoya Basden",
    thumbnail:
      "https://yt3.ggpht.com/FXW8rp5C5mXd9a7vSPnI1zhyka1sMlcwTPmYMs_VNUVeKnC38lJULQmwwH8K8V3Bqx3rqUeN=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@AReallyGoodCry": {
    id: "UCf3wbEjxSCMb2I0QU_BnrAA",
    title: "A Really Good Cry with Radhi Devlukia",
    thumbnail:
      "https://yt3.ggpht.com/vwkmhqaKSwe-bsApD5JIiHhXLK0fmptQaOhdG77rG7b4HVsqCg2wdDIXC22eYyLmf4dROVVG3RA=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@thesqueeze": {
    id: "UCrmdj2x2Va7I30cBqWfXE7A",
    title: "The Squeeze",
    thumbnail:
      "https://yt3.ggpht.com/6g2BQTP99lm55uM5t-b1MRU9gcOBhW89qSwIqXr1E-t2X_fpPWQ1NDqkUeglLlm1ULqfRIOcrQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@SleepMagicfm": {
    id: "UCbLmtnlFdZzxfnWYxCgdVTQ",
    title: "Sleep Magic: Sleep Hypnosis",
    thumbnail:
      "https://yt3.ggpht.com/b6Y97Zyzucav78m48LO4gqQkkVrJo-ll1Sj7S58WXwgeCUwCcifYfWb2mq0C2FjZYtUiJqpnfg=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@GretchenRubin": {
    id: "UCxi4ZHo3mdK3RLWngmygDUw",
    title: "Gretchen Rubin",
    thumbnail:
      "https://yt3.ggpht.com/PaKVRqk_VnQSLrxKoMZmXRGt9aJy8zF3IYyzFKqNxQjzzzvD3vbQ6Owi_sLgiJKtBITushK7zg=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@MonaSharmaWellness": {
    id: "UCpuQq1IrtKdJPRs80AjuxoA",
    title: "Mona Sharma",
    thumbnail:
      "https://yt3.ggpht.com/JpkC8YLt-YTPAlfdwd-bdwM-4uyJqxdYfGD64OV5cBiDDvGBlwViH0k3LmGeYpfL9VQtI9cR=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@CareCredit1": {
    id: "UC9JvNAHamDSy5-aP3HVH9vQ",
    title: "CareCredit",
    thumbnail:
      "https://yt3.ggpht.com/BnjxK_eNbftDxgdghwdE30Hu5mUtbgC0Hq9OO0thyLEH8jxJk7ymfVmxkQxgyk1iGAqaujtmKg=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@jjvirginvideos": {
    id: "UC9jpWhLL36llg1gvesoNlMw",
    title: "JJ Virgin",
    thumbnail:
      "https://yt3.ggpht.com/LNvtr5TNAcz71JadC2FT6jIlUtb9aw4G8MarduK6VbOaUGa6PmyIlO2fQ5jJe5dDKObXzMvf=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@maxlugavere": {
    id: "UCw-1kRP-tFmU7Byesum5XaQ",
    title: "Max Lugavere",
    thumbnail:
      "https://yt3.ggpht.com/7xNQ0ekvOVw97JACrmx0szzZd5o5WSEOAyTVFAkOAL99ntNZyY7pyZ9Bq33gB6FBjdCQOmpc6A=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@robertfkennedyjrofficial": {
    id: "UC4ewc5vQcMpLticvqPDcSQw",
    title: "Robert F. Kennedy Jr.",
    thumbnail:
      "https://yt3.ggpht.com/A0wRdNSJL70ys7l2fXKWSJBxVO1fGGh-xEn8wugjOf6YdCrtZauYJlwGYM0zvlJU-koVMyvctw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@affirmationsforblackgirls": {
    id: "UC4g90rFmTGCHcgku8LM5mNg",
    title: "Affirmations for Black Girls",
    thumbnail:
      "https://yt3.ggpht.com/9Y-AybtI3bAfnANu5JxdtMA9iOsd45aaF5Wt83CFKOpplzKkX2lYyxUm20f74Mjb7TWVH8Fbuw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@karaloewentheil5301": {
    id: "UCksNe_YnsMaYyIYvxW2SDFg",
    title: "Kara Loewentheil",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_k8p7MBm1UmsYtlhGD_DpQ1aaPJxN0Qg2a6IHFYAJ_JZQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@DrWillCole": {
    id: "UCccrTmRLX_EPUGlUnRypuKg",
    title: "Dr. Will Cole",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_k0-MXvDa0TRqBJdPRYzQJKQ_navC_rS-royY6WAOFecoY=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@TheOneYouFeedPod": {
    id: "UCLj9ThNsqJyLasI_XQFslQg",
    title: "TheOneYouFeedPod",
    thumbnail:
      "https://yt3.ggpht.com/SQCPpu0RG3hH6rtizaCsAp9oEwcVMQKLk6MUktPxG0wxC79el99SF4Z2dK1KIC6cR35kNDrsXA=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@WellwithArielleLorre": {
    id: "UCyRxtHZSa9nyYJVBcAVLH_Q",
    title: "Well with Arielle Lorre",
    thumbnail:
      "https://yt3.ggpht.com/0dqP50MKT4JJjyVPcTi323S-eN_NGXInvNcus_NKVqjzLLdhqZqDO-JpQPGOzObblOcOinAdsGM=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@DhruPurohit": {
    id: "UCBnLhz50WZAvsF4S72xtESA",
    title: "Dhru Purohit",
    thumbnail:
      "https://yt3.ggpht.com/zDE1Sw81wTMiMr4-551SXvGDeslB3cFc4oQH_po32kGa_se74L8dzjovjH-l3ZpsUpNQCbjfng=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@ForrestHanson": {
    id: "UCav9FeKrYOP4JYoetd-d8XA",
    title: "Forrest Hanson",
    thumbnail:
      "https://yt3.ggpht.com/LOAPrOr3XUBm-j77ElXLMc0c75gkODcNfuw9TwD81KG8nrHe6uGpmvkpwNTylOseRgRinlqDBsw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@MelissaWoodHealth": {
    id: "UCd-M8iMVPXC9qE-4s8B9law",
    title: "Melissa Wood Health",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_nO39N9wf3Mc2vmwyUohtbb74RsX2A_-UYYCy0dbJKlK4Q=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@thenickbarepodcast": {
    id: "UCF494AxFwZl2BG3ySp9ehsQ",
    title: "The Nick Bare Podcast",
    thumbnail:
      "https://yt3.ggpht.com/pbuUgLaI1RHdCcS9bb-DCSwzXK5CBUeVwi69yMK9gehAdqo9soAlldhpQrTZph0zXkECIuoAkBQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@ADHD_Chatter_Podcast": {
    id: "UCCKrIhEGR5yoCBWsCkzGEaA",
    title: "ADHD Chatter Podcast",
    thumbnail:
      "https://yt3.ggpht.com/-Ba4x77-yuKm6Q6Tf-sF26PFxIAXZWK_OHA5IZn1g5P2QNY5iRlCnuzszvmr06g2AH-aIcKfiw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@TheCurbsiders": {
    id: "UCHGfC9YOG2NUMHlf7uhEd8g",
    title: "The Curbsiders Internal Medicine Podcast",
    thumbnail:
      "https://yt3.ggpht.com/GRjcPohMdWdlk8UDDrMtbSMbgzNLYyhyUy95pob_XWJjY-krh8WF7ncpnD85JNaNXkfzg_NRBb0=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@MightyPursuit": {
    id: "UCCABNvVKvXxO-YqdS9CM8WA",
    title: "Mighty Pursuit",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_l1JjS9gWYmClII5bw_LK5j5LSrjI8D25HhoKJL7dmk14k=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@tracyotsuka4796": {
    id: "UCMP64ur22doXXeqMYmJ0YIw",
    title: "Tracy Otsuka",
    thumbnail:
      "https://yt3.ggpht.com/k5zDvTwNULokbphk2S3v869dvFdtHq5P4iUPGL9OgW--Z6TVWUrd1gFd_IxctR9yT-fV6S_ilQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@StraightANursing": {
    id: "UCJK-mbh6udF6WNYdjJQ-LYA",
    title: "Straight A Nursing",
    thumbnail:
      "https://yt3.ggpht.com/KkgotkLyJ_1wacH17LzLnySBukp-W3hdmgt0-4_6munLfD4iVyE05ri6kYcRznOHhSFwEvvU=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@TheShawnModel": {
    id: "UCtFV6nJ5zZ0__SXBkikfLlg",
    title: "Shawn Stevenson",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_mQ_EArmBb6pTcbjnJsyyBXUGcOvMTtrMVatSNdGgiZ_g=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@vanessaandxander": {
    id: "UCzf8xqC-GJd5tSPYYOjFiOw",
    title: "Vanessa and Xander Marin",
    thumbnail:
      "https://yt3.ggpht.com/8PF-_L5Sm3cfP9_wbgvbTtSW1IX7nG20VCiRgP-vh3UQO86_JWzkQgFM-0NdKlCdD17ntyQuLW8=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
};
