import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { ENDPOINTS } from "apis";
import { ChatMessageRatingEnum, ChatUserType, IChatResponse, IChatSession } from "apis/schema/chat";
import { IChannelListResponse } from "apis/schema/youtube";
import { ChannelHeader } from "components/ChannelHeader";
import { ChannelHeaderRow } from "components/ChannelHeader/ChannelHeaderRow";
import ChatRow from "components/ChatRow";
import { CHANNELS_INFO } from "pages/Chat/channels";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "routings";
import { useChatSession } from "shared/contexts/ChatSession";
import { useSnack } from "shared/contexts/Snack";
import api from "shared/utils/api";
import { COLORS } from "themes/theme";

const YouTubeChannelChat = () => {
  const {
    currentChatSession,
    setCurrentChatSession,
    addChatSession,
    updateChatSession,
    // llmModel,
    currentChatSessionMessageLength,
  } = useChatSession();

  const { snackError } = useSnack();
  const { channelHandle } = useParams();
  // TODO: handle casing issue in channelHandle
  const channelId = channelHandle && CHANNELS_INFO[channelHandle].id;

  const navigate = useNavigate();

  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [channelResponse, setChannelResponse] = useState<IChannelListResponse>();
  const [chatCount, setChatCount] = useState(0);

  const lastChatRowRef = useRef<HTMLDivElement | null>(null);
  const scrollToLastChatRow = () => {
    if (lastChatRowRef.current) {
      lastChatRowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    if (message.trim() === "") {
      return; // Don't send empty messages
    }
    setChatCount((n) => n + 1);
    setIsLoading(true);
    try {
      if (currentChatSession) {
        const response = await api.post(ENDPOINTS.chat, {
          sessionId: currentChatSession.sessionId,
          text: message,
          // model: llmModel,
          channelIds: [channelId],
        });
        const chatData = response as IChatResponse;
        updateChatSession(chatData);
      } else {
        // Start a new chat session if currentChatSession is null
        const response = await api.post(ENDPOINTS.chat, {
          text: message,
          // model: llmModel,
          channelIds: [channelId],
        });
        const chatData = response as IChatResponse;

        const now = new Date();
        const newChatSession: IChatSession = {
          sessionId: chatData.sessionId,
          // name: "New Chat", // Provide a name for the new chat session
          updatedAt: now.toISOString(),
          chatHistory: chatData.chatHistory,
        };
        setCurrentChatSession(newChatSession);
        addChatSession(newChatSession);
      }
    } catch (error) {
      console.log(error);
      snackError("We are currently at capacity. Please try again later.");
    }
    setMessage("");
    setIsLoading(false);
  };

  const handleRatingsClick = async (rating: ChatMessageRatingEnum, index?: number) => {
    if (index !== undefined && currentChatSession !== null) {
      const updatedSession = { ...currentChatSession, channelId: channelId };
      if (updatedSession.chatHistory[index].additional_kwargs.rating === rating) {
        updatedSession.chatHistory[index].additional_kwargs.rating = ChatMessageRatingEnum.Neutral;
      } else {
        updatedSession.chatHistory[index].additional_kwargs.rating = rating;
      }

      const response = await api.put(
        `${ENDPOINTS.chatSessions}/${currentChatSession.sessionId}`,
        updatedSession,
      );
      const chatData = response as IChatResponse;
      updateChatSession(chatData);
    }
  };

  useEffect(() => {
    if (!channelId) {
      navigate(PATHS.home);
    } else {
      const fetchData = async () => {
        try {
          const response = await api.get(ENDPOINTS.channelInfoYouTube, { id: channelId });
          const channelInfoData = response as IChannelListResponse;
          setChannelResponse(channelInfoData);
        } catch (error) {
          console.error("Error fetching channel info: ", error);
        }
      };
      fetchData();
    }
  }, [channelId]);

  useEffect(() => {
    scrollToLastChatRow();
  }, [currentChatSessionMessageLength]);

  useEffect(() => {
    scrollToBottom();
  }, [chatCount]);

  const sendButton = (
    <Button
      variant="contained"
      color="primary"
      onClick={handleSendMessage}
      disabled={message.trim().length === 0 || isLoading}>
      <SendIcon />
    </Button>
  );

  const loadingChatBoxes = (
    <Stack sx={{ display: isLoading ? "block" : "none" }}>
      <ChatRow userType={ChatUserType.human} text={message} onRatingsClick={handleRatingsClick} />
      <ChatRow
        userType={ChatUserType.ai}
        text={""}
        isLoading={true}
        onRatingsClick={handleRatingsClick}
      />
    </Stack>
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://substackapi.com/widget.js";

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    window.CustomSubstackWidget = {
      substackUrl: "thesynthesisapp.substack.com",
      placeholder: "Type your email...",
      buttonText: "Subscribe",
      theme: "orange",
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      delete window.CustomSubstackWidget;
    };
  }, []);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Box px={{ sm: 2, md: 10, lg: 20 }}>
      <Box>
        {channelResponse?.items && channelResponse.items.length !== 0 && (
          <Box ref={ref}>
            <ChannelHeader channel={channelResponse.items[0]} />
          </Box>
        )}
        {channelResponse?.items && channelResponse.items.length !== 0 && !inView && (
          <ChannelHeaderRow channel={channelResponse.items[0]} />
        )}
        <ChatRow
          key={"initial-chat-message"}
          userType={ChatUserType.ai}
          text={"What would you like to discuss?"}
          onRatingsClick={handleRatingsClick}
          disableRating={true}
        />
        <div className="chat-history">
          {currentChatSession &&
            currentChatSession.chatHistory
              .slice(0, -2)
              .map((message, index) => (
                <ChatRow
                  key={index}
                  index={index}
                  userType={ChatUserType[message.type as ChatUserType]}
                  text={message.content}
                  rating={message.additional_kwargs.rating}
                  onRatingsClick={handleRatingsClick}
                />
              ))}
        </div>
        <Box ref={lastChatRowRef}></Box>
        <div className="chat-history">
          {currentChatSession &&
            currentChatSession.chatHistory
              .map((message, index) => (
                <ChatRow
                  key={index}
                  index={index}
                  userType={ChatUserType[message.type as ChatUserType]}
                  text={message.content}
                  rating={message.additional_kwargs.rating}
                  onRatingsClick={handleRatingsClick}
                />
              ))
              .slice(-2)}
        </div>
        {loadingChatBoxes}
        <Box ref={bottomRef}></Box>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          pt: 2,
          backgroundColor: COLORS.backgroundDark,
        }}>
        <TextField
          color="secondary"
          label="Type your message..."
          variant="outlined"
          fullWidth
          value={message}
          onChange={handleInputChange}
          InputProps={{ endAdornment: sendButton, sx: { borderRadius: "0.8rem" } }}
          multiline={true}
          disabled={isLoading}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
        <Stack sx={{ display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
          <Box my={1} id="custom-substack-embed"></Box>
          <Typography fontSize={13} textAlign={"center"}>
            Subscribe for exclusive features
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export { YouTubeChannelChat };
