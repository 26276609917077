import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import VideocamIcon from "@mui/icons-material/Videocam";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Modal, Stack, Typography } from "@mui/material";
import { IChannelListItem } from "apis/schema/youtube";
import React from "react";
import { numberWithCommas } from "shared/helpers";
import { COLORS } from "themes/theme";

export interface ChannelDetailsModalProps {
  open: boolean;
  onClose: () => void;
  data: IChannelListItem;
}

const ChannelDetailsModal: React.FC<ChannelDetailsModalProps> = ({ open, onClose, data }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: COLORS.backgroundGrey,
          p: 4,
          borderRadius: "0.8rem",
        }}>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <img
            src={data.snippet?.thumbnails.high?.url}
            alt="Channel logo"
            style={{ width: "100px", height: "100px", borderRadius: "50%", marginRight: "20px" }}
          />
          <Stack spacing={2}>
            <Typography variant="h6" component="h2">
              {data.snippet?.title}
            </Typography>
            {data.snippet?.customUrl && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <OpenInNewIcon />
                <Box
                  onClick={() =>
                    window.open(`https://www.youtube.com/${data.snippet?.customUrl}`, "_blank")
                  }
                  sx={{ cursor: "pointer" }}>
                  <Typography>{`www.youtube.com/${data.snippet?.customUrl}`}</Typography>
                </Box>
              </Stack>
            )}
            <Stack direction="row" alignItems="center" spacing={1}>
              <SubscriptionsIcon />
              <Typography>
                Subscribers: {numberWithCommas(data.statistics?.subscriberCount)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <VideocamIcon />
              <Typography>Videos: {numberWithCommas(data.statistics?.videoCount)}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <VisibilityIcon />
              <Typography>Views count: {numberWithCommas(data.statistics?.viewCount)}</Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export { ChannelDetailsModal };
