import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "routings";
import { COLORS } from "themes/theme";

const StripePaymentCancel = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate(PATHS.pricing);
    }, 3000);
  }, []);
  return (
    <Box sx={{ p: "2rem" }}>
      <Typography fontWeight={"bold"}>Payment cancelled.</Typography>
      <Typography fontWeight={"bold"}>You will be redirected in 3 seconds.</Typography>
      <Button
        variant="contained"
        onClick={() => navigate(PATHS.channels)}
        sx={{ mt: "2rem", background: COLORS.secondary, color: "black" }}>
        Redirect now
      </Button>
    </Box>
  );
};

export default StripePaymentCancel;
