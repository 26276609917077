import { AccountCircle } from "@mui/icons-material";
// import SavingsIcon from "@mui/icons-material/Savings";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ENDPOINTS } from "apis";
import { IUser } from "apis/schema/core";
import synthesisLogoWithText from "assets/images/icon-white-with-text.png";
import { FC, MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "routings";
import { googleSignInButtonId, useAuth } from "shared/contexts/Auth";
// import { useBalance } from "shared/contexts/Balance";
import useFeatureFlags from "shared/hooks/useFeatureFlags";
import api from "shared/utils/api";
import { theme } from "themes";

const AppBar: FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const auth = useAuth();
  const featureFlags = useFeatureFlags();
  const isHiddenMenuEnabled = featureFlags.isEnabled("HIDDEN_MENU");

  const [me, setMe] = useState<IUser>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const { balance } = useBalance();
  // const [balanceAnchor, setBalanceAnchor] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    const getData = async () => {
      const getUser = api.get(ENDPOINTS.me);
      try {
        const userProfile = (await getUser) as IUser;
        setMe(userProfile);
      } catch (err: any) {
        if (err.status === 401) return;
        console.log(err);
      }
    };
    auth.authToken && getData();
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  // const isBalanceMenuOpen = Boolean(balanceAnchor);

  const handleProfileMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // const handleBalanceMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
  //   setBalanceAnchor(event.currentTarget);
  // };
  // const handleBalanceMenuClose = () => {
  //   setBalanceAnchor(null);
  // };

  const handleLogout = () => {
    setAnchorEl(null);
    auth.logout();
  };

  const hiddenMenuItems = (
    <Box>
      {/* <MenuItem onClick={() => navigate(PATHS.chat)}>Chat</MenuItem> */}
      {/* <MenuItem onClick={() => navigate(PATHS.subscribe)}>Subscribe</MenuItem>
      <MenuItem onClick={() => navigate(PATHS.youTube)}>YouTube search</MenuItem>
      <MenuItem onClick={() => navigate(PATHS.youTubeProcessStatus)}>
        YouTube Process Status
      </MenuItem>
      <MenuItem onClick={() => navigate(PATHS.notionConnect)}>Connect Notion</MenuItem> */}
    </Box>
  );

  const renderProfile = (
    <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
      {me ? <Avatar src={me.picture} /> : <AccountCircle />}
    </IconButton>
  );
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem onClick={() => navigate(PATHS.profile)}>Profile</MenuItem>
      {auth.isSubscribed && <MenuItem onClick={() => navigate(PATHS.channels)}>Channels</MenuItem>}
      {auth.isSubscribed && <MenuItem onClick={() => navigate(PATHS.chat)}>Chat</MenuItem>}
      {!auth.isSubscribed && <MenuItem onClick={() => navigate(PATHS.pricing)}>Pricing</MenuItem>}
      <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
      {isHiddenMenuEnabled && hiddenMenuItems}
    </Menu>
  );
  // const renderBalance = (
  //   <Box p={1} display="flex" alignItems={"center"}>
  //     <IconButton edge="end" onClick={handleBalanceMenuOpen} color="inherit">
  //       <SavingsIcon />
  //     </IconButton>
  //     {!isMobile && <Box p={1}>{balance?.available}</Box>}
  //   </Box>
  // );
  // const renderBalanceMenu = (
  //   <Menu
  //     anchorEl={balanceAnchor}
  //     anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
  //     keepMounted
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     open={isBalanceMenuOpen}
  //     onClose={handleBalanceMenuClose}>
  //     <MenuItem>Available: {balance?.available}</MenuItem>
  //     <MenuItem>Current: {balance?.current}</MenuItem>
  //   </Menu>
  // );

  return (
    <Box
      sx={{
        boxShadow: "none",
        width: "100vw",
        maxWidth: 1020,
        overflowX: "auto",
        pl: { xs: 2, xsm: 3, md: 4 },
        pr: { xs: 2, xsm: 3, md: 4 },
        mt: 4,
      }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Grid container alignItems={"center"}>
          <Grid item>
            <Box display="flex" alignItems="center">
              <img
                src={synthesisLogoWithText}
                height={isMobile ? "50px" : "80px"}
                onClick={() => navigate(PATHS.home)}
                style={{ cursor: "pointer" }}
              />
            </Box>
          </Grid>
          <Grid item></Grid>
        </Grid>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              onClick={() => navigate(PATHS.channels)}
              color="inherit"
              sx={{ mr: 0.5 }}>
              <YouTubeIcon />
            </IconButton>
            <IconButton
              edge="end"
              onClick={() => navigate(PATHS.chat)}
              color="inherit"
              sx={{ mr: 0.5 }}>
              <SmartToyIcon />
            </IconButton>
            {!auth.isSubscribed && (
              <IconButton
                edge="end"
                onClick={() => navigate(PATHS.pricing)}
                color="inherit"
                sx={{ mr: 0.5 }}>
                <CurrencyPoundIcon />
              </IconButton>
            )}
            {/* <IconButton edge="end" onClick={() => navigate(PATHS.podcast)} color="inherit">
              <PodcastsIcon />
            </IconButton> */}
          </>
        ) : (
          <>
            <Box p={1} color={"white"}>
              <Link href={PATHS.channels} color="inherit" underline="none" sx={{ mr: 2 }}>
                Channels
              </Link>
              <Link href={PATHS.chat} color="inherit" underline="none" sx={{ mr: 2 }}>
                Chat
              </Link>
              {!auth.isSubscribed && (
                <Link href={PATHS.pricing} color="inherit" underline="none" sx={{ mr: 2 }}>
                  Pricing
                </Link>
              )}
            </Box>
            {/* <Box p={1} color={"white"}>
              <Link href={PATHS.podcast} color="inherit" underline="none">
                Podcast
              </Link>
            </Box> */}
          </>
        )}
        {/* {auth.authToken && renderBalance} */}
        {/* {renderBalanceMenu} */}
        <div id={googleSignInButtonId}></div>
        {auth.authToken && renderProfile}
        {renderMenu}
      </Toolbar>
    </Box>
  );
};

// Get AppBar Height, usage:
// appBarHeight = useAppBarHeight()
// height: calc(100vh - ${appBarHeight}px)
// https://github.com/mui/material-ui/issues/10739
type MinHeight = {
  minHeight: number;
};

export const useAppBarHeight = (): number => {
  const {
    mixins: { toolbar },
    breakpoints,
  } = useTheme();
  const toolbarDesktopQuery = breakpoints.up("sm");
  const toolbarLandscapeQuery = `${breakpoints.up("xs")} and (orientation: landscape)`;
  const isDesktop = useMediaQuery(toolbarDesktopQuery);
  const isLandscape = useMediaQuery(toolbarLandscapeQuery);
  let currentToolbarMinHeight;
  if (isDesktop) {
    currentToolbarMinHeight = toolbar[toolbarDesktopQuery];
  } else if (isLandscape) {
    currentToolbarMinHeight = toolbar[toolbarLandscapeQuery];
  } else {
    currentToolbarMinHeight = toolbar;
  }
  return (currentToolbarMinHeight as MinHeight).minHeight;
};

export { AppBar };
