const useFeatureFlags = () => {
  const enabledFeaturesString = process.env.REACT_APP_FEATURE_FLAGS || "";
  const enabledFeatures = enabledFeaturesString.split(",").map((x) => x.trim());

  const isEnabled = (feature: string) => {
    return enabledFeatures.includes(feature);
  };

  const isAllEnabled = (features: string[]) => {
    const requiredSet = new Set(features);
    const intersection = new Set(enabledFeatures.filter((element) => requiredSet.has(element)));

    return intersection.size === requiredSet.size;
  };

  return {
    enabledFeatures,
    isEnabled,
    isAllEnabled,
  };
};

export default useFeatureFlags;
