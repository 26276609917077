export const numberWithCommas = (x: number | string | null | undefined): string => {
  if (x === null || x === undefined) {
    return "N/A";
  }

  // Convert x to a string if it's not already
  const numStr = String(x);

  // Check if the string is a valid number using a regular expression
  if (/^\d+(\.\d+)?$/.test(numStr)) {
    // Format the number with commas
    return new Intl.NumberFormat("en-US").format(Number(numStr));
  } else {
    return "N/A";
  }
};
