import { Typography } from "@mui/material";
import { FC } from "react";

const TermsAndConditions: FC = () => {
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography variant="body1" gutterBottom>
        &apos;We&apos;, &apos;us&apos; and &apos;our&apos; refers to The Synthesis. &apos;You&apos;
        refers to the user or viewer of our website/application.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"} gutterBottom>
        1. Introduction
      </Typography>
      <Typography variant="body1" gutterBottom>
        These terms and conditions govern your use of our website/application. By using our
        website/application, you accept these terms and conditions in full. If you disagree with
        these terms and conditions or any part of them, you must not use our website/application.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"} gutterBottom>
        2. Intellectual Property
      </Typography>
      <Typography variant="body1" gutterBottom>
        All content on our website/application, including but not limited to text, graphics, logos,
        icons, images, audio clips, and software, is the property of our company or our licensors
        and is protected by copyright and other intellectual property laws. You may not copy,
        reproduce, distribute, transmit, display, or create derivative works of any content without
        our prior written consent.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"} gutterBottom>
        3. User Conduct
      </Typography>
      <Typography variant="body1" gutterBottom>
        You agree to use our website/application only for lawful purposes and in a manner that does
        not infringe upon the rights of, restrict, or inhibit anyone else&apos;s use and enjoyment
        of the website/application. You also agree not to: - Use our website/application to post,
        transmit or otherwise distribute any content that is unlawful, defamatory, obscene, or
        offensive; - Impersonate any person or entity, including but not limited to our employees or
        representatives; - Use our website/application in any manner that could damage, disable,
        overburden, or impair our servers or networks; - Attempt to gain unauthorised access to any
        portion of our website/application, or any other accounts, computer systems, or networks
        connected to our website/application, through hacking, password mining or any other means; -
        Collect or store personal information about other users of our website/application without
        their express consent.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"} gutterBottom>
        4. User Content
      </Typography>
      <Typography variant="body1" gutterBottom>
        By posting or uploading content to our website/application, you grant us a non-exclusive,
        worldwide, royalty-free, perpetual, irrevocable, and fully sub-licensable right to use,
        reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and
        display such content throughout the world in any media. You also grant us the right to use
        the name you submit in connection with such content.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"} gutterBottom>
        5. Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" gutterBottom>
        We make no representations or warranties of any kind, express or implied, as to the
        operation of our website/application or the information, content, materials, or products
        included on our website/application. You expressly agree that your use of our
        website/application is at your sole risk.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"} gutterBottom>
        6. Limitation of Liability
      </Typography>
      <Typography variant="body1" gutterBottom>
        We shall not be liable for any damages of any kind arising from the use of our
        website/application, including but not limited to direct, indirect, incidental, punitive,
        and consequential damages.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"} gutterBottom>
        7. Indemnification
      </Typography>
      <Typography variant="body1" gutterBottom>
        You agree to indemnify, defend, and hold harmless our company and its affiliates, officers,
        directors, employees, and agents from and against any claims, actions, or demands, including
        without limitation reasonable legal and accounting fees, alleging or resulting from your use
        of our website/application or your breach of these terms and conditions.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"} gutterBottom>
        8. Governing Law
      </Typography>
      <Typography variant="body1" gutterBottom>
        These terms and conditions shall be governed by and construed in accordance with the laws of
        the United Kingdom. Any disputes arising out of or in connection with these terms and
        conditions shall be subject to the exclusive jurisdiction of the courts of the United
        Kingdom.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"} gutterBottom>
        9. Changes to Terms and Conditions
        <Typography variant="body1" gutterBottom>
          We reserve the right to modify these terms and conditions at any time without prior
          notice. By using our website/application after any such modification, you agree to be
          bound by the revised terms and conditions.
        </Typography>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Last updated: 2023-04-01
      </Typography>
    </>
  );
};

export { TermsAndConditions };
