import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { FC } from "react";
import { AuthProvider } from "shared/contexts/Auth";
// import { BalanceProvider } from "shared/contexts/Balance";
import { ChatSessionProvider } from "shared/contexts/ChatSession";
import { SnackProvider } from "shared/contexts/Snack";
import { MainRoutes } from "./routings";
import { darkTheme } from "./themes";

// export const google = window.google;

const App: FC = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <SnackProvider>
        <AuthProvider>
          {/* <BalanceProvider> */}
          <ChatSessionProvider>
            <MainRoutes />
          </ChatSessionProvider>
          {/* </BalanceProvider> */}
        </AuthProvider>
      </SnackProvider>
    </ThemeProvider>
  );
};

export default App;
