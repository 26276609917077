import React, { createContext, useContext, useState } from "react";

export enum SnackSeverity {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}

export interface SnackContextType {
  isShowingSnack: boolean;
  snackMessage: string;
  snackSeverity: SnackSeverity;
  snackSuccess: (message: string) => void;
  snackError: (message: string | Error | unknown) => void;
  forceCloseSnack: () => void;
}

export const SnackContext = createContext<SnackContextType>({
  snackError: () => {},
  snackSuccess: () => {},
  isShowingSnack: false,
  snackSeverity: SnackSeverity.error,
  snackMessage: "",
  forceCloseSnack: () => {},
});

export const useSnack = () => useContext(SnackContext);

export const SnackProvider = ({ children }: { children: React.ReactNode }) => {
  const [isShowingSnack, setIsShowingSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [snackSeverity, setSnackSeverity] = useState<SnackSeverity>(SnackSeverity.error);

  const snackError = (message: string | Error | unknown) => {
    setSnackSeverity(SnackSeverity.error);
    setSnackMessage(`${message}`);
    setIsShowingSnack(true);
    setTimeout(() => {
      setIsShowingSnack(false);
    }, 3000);
  };

  const snackSuccess = (message: string) => {
    setSnackSeverity(SnackSeverity.success);
    setSnackMessage(`${message}`);
    setIsShowingSnack(true);
    setTimeout(() => {
      setIsShowingSnack(false);
    }, 3000);
  };

  const forceCloseSnack = () => {
    setIsShowingSnack(false);
  };

  const value = {
    snackError,
    snackSuccess,
    isShowingSnack,
    snackSeverity,
    snackMessage,
    forceCloseSnack,
  };

  return <SnackContext.Provider value={value}>{children}</SnackContext.Provider>;
};
